import styled from 'styled-components'
import { ATHENS_GRAY_DARK } from 'constants/colors'


export const TextTogglerContainer = styled.div`
  position: relative;
  border: 1px solid ${ATHENS_GRAY_DARK};
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  margin-top: 10px;
`
