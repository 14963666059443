import React, { FC, ReactNode, useState } from 'react'
import moment from 'moment'
import { DATE_PICKER_FREQUENCIES } from 'uiKit/DateControlNew/DateControl'

const DateControlContext = React.createContext({
  startDate: moment().subtract(1, 'week').add(1, 'day'),
  endDate: moment(),
  frequency: null,
  setFrequency: null,
  onChange: null,
  resetDate: null,
})

const DateControlProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [startDate, setStartDate] = useState(moment().subtract(1, 'week').add(1, 'day'))
  const [endDate, setEndDate] = useState(moment())
  const [frequency, setFrequency] = useState(DATE_PICKER_FREQUENCIES.week)

  const onChange = ({ startDate, endDate, frequency }) => {
    setStartDate(startDate)
    setEndDate(endDate)
    setFrequency(frequency)
  }

  const resetDate = () => {
    setStartDate(moment().subtract(1, 'week').add(1, 'day'))
    setEndDate(moment())
    setFrequency(DATE_PICKER_FREQUENCIES.week)
  }

  return (
    <DateControlContext.Provider
      value={{
        startDate,
        endDate,
        onChange,
        frequency,
        setFrequency,
        resetDate,
      }}>
      {children}
    </DateControlContext.Provider>
  )
}

export { DateControlProvider, DateControlContext }
