import styled from 'styled-components'
import { WHITE } from 'constants/colors'

const Container = styled.div`
  background-color: ${WHITE};
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
`

const TableWrap = styled.div`
  display: flex;
  min-height: 400px;
`

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
  justify-content: center;
  width: 100%;
`

export { Container, TableWrap, LoaderContainer }
