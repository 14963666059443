import styled from 'styled-components'
import { CORNFLOWER_BLUE, RIBBON_BLUE } from 'constants/colors'

const TextMessageButton = styled.div<{ highlight: boolean }>`
  border: 1px solid ${({ highlight }) => (highlight ? RIBBON_BLUE : CORNFLOWER_BLUE)};
  border-radius: 8px;
  height: fit-content;
`

const ChatBubble = styled.span<{ isRtl: boolean }>`
  padding: 8px 16px;
  font-size: 14px;
  background: rgba(90, 152, 247, 0.08);
  display: inline-block;
  width: 300px;
  cursor: pointer;
  direction: ${({ isRtl }) => (isRtl ? 'rtl' : 'ltr')};
`

export { TextMessageButton, ChatBubble }
