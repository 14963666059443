import {
  CLOSE_INVITE_USER_MODAL,
  CLOSE_USER_ACCESS_MODAL,
  OPEN_INVITE_USER_MODAL,
  OPEN_USER_ACCESS_MODAL
} from '../actions/contactsModalsAction'

const userAccessModalInitialState = {
  isUserAccessModalOpen: false,
  isActive: false
}

export const isInviteNewUserModalOpen = (state = null, action) => {
  switch (action.type) {
    case OPEN_INVITE_USER_MODAL:
    case CLOSE_INVITE_USER_MODAL:
      return action.isInviteNewUserModalOpen
    default:
      return state
  }
}

export const userAccessModalState = (state = userAccessModalInitialState, action) => {
  switch (action.type) {
    case OPEN_USER_ACCESS_MODAL:
      return {
        isUserAccessModalOpen: action.isUserAccessModalOpen,
        isActive: action.isActive,
        contactId: action.contactId
      }
    case CLOSE_USER_ACCESS_MODAL:
      return {
        isUserAccessModalOpen: action.isUserAccessModalOpen
      }
    default:
      return state
  }
}
