import React, { useEffect, useState } from 'react'

import { alertError } from 'api'
import { isStringEmpty } from 'helpers/isStringEmpty'
import { IntentType, NlpRequestType } from 'models/IntentsType'
import { OptionItemType } from 'models/MessageTypes'
import ModalTemplate from 'uiKit/ModalTemplate'
import Input from 'uiKit/inputs/Input'
import AtomSelect from 'uiKit/AtomSelect/AtomSelect'
import UserSaysTab from '../UserSaysTab'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import { BotLanguages } from 'tabs/nlp/actions/botLanguages'

import { createNewIntent, getIntentUserSays, updateIntent, trainIntent } from '../../api/nlp'

import * as S from './IntentModal.style'

interface Props {
  open: boolean
  onClose: () => void
  modalIntentId: number
  isFaqModal: boolean
  intents: IntentType[]
  trainUserPhrase: NlpRequestType
  onSelect: (a: OptionItemType) => void
  activeBot: any
  callback: any
  botLanguages: BotLanguages | undefined | null
}

export const IntentModal: React.FC<Props> = ({
  open,
  onClose,
  modalIntentId,
  isFaqModal,
  activeBot,
  intents,
  trainUserPhrase,
  onSelect,
  callback,
  botLanguages,
}) => {
  const [intentName, setIntentName] = useState('')
  const [selectedAtom, setSelectedAtom] = useState('')
  const [activeLanguages, setActiveLanguages] = useState([])
  const [expanded, setExpanded] = useState('')
  const [userSays, setUserSays] = useState([])
  const [intentId, setIntentId] = useState(null)
  const [odinId, setOdinId] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isFaq, setIsFaq] = useState(isFaqModal)

  useEffect(() => {
    if (botLanguages) {
      setActiveLanguages(botLanguages.activeLanguages)
      setUserSays(createUserSays(botLanguages.activeLanguages))
    }

    if (modalIntentId) {
      getIntentUserSays(modalIntentId).then(intent => {
        if (intent) {
          setIntentName(intent.name)
          setUserSays(intent.userSays)
          setSelectedAtom(intent.answerAtom)
          setIntentId(intent.intentId)
          setOdinId(intent.odinId)
          setIsFaq(!!intent.answerAtom)
        }
      })
    }

    setLoading(false)
  }, [botLanguages])

  useEffect(() => {
    if (trainUserPhrase) {
      setExpanded(trainUserPhrase?.language?.shortName)
    }
  }, [trainUserPhrase])

  const getModalTitle = () => {
    return `${modalIntentId ? `Edit ${intentName}` : 'Create new'} ${isFaq ? 'FAQ' : 'Intent'}`
  }

  const createUserSays = languages => {
    const userSays = []
    languages.forEach(language => {
      userSays.push({
        isEdited: false,
        shortName: language.shortName,
        phrases: [],
      })
    })
    return userSays
  }

  const handleSaveIntent = () => {
    const errorMessage = validateIntent()

    if (errorMessage) {
      alertError(errorMessage)
    } else {
      const newIntent = {
        intentId,
        userSays,
        id: null,
        odinId: odinId,
        faq: isFaq,
        name: intentName,
        answerAtom: selectedAtom,
        botId: activeBot.id.toString(),
      }
      sendRequest(newIntent)
    }
  }

  const validateIntent = () => {
    const intent = intents.find(intent => {
      return intent.name.toUpperCase() === intentName.toUpperCase() && intent.id !== modalIntentId
    })
    const isNotUserSay = userSays.some(userSay => !userSay?.phrases.length)

    if (isStringEmpty(intentName)) {
      return `Please write ${isFaq ? 'FAQ' : 'Intent'} name`
    } else if (intentName.length > 255) {
      return `${isFaq ? 'FAQ' : 'Intent'} name should be less than 256 symbols`
    } else if (isFaq && !selectedAtom) {
      return 'Please choose answer'
    } else if (intent) {
      return `Intent with name '${intent.name}' already exist`
    } else if (isNotUserSay) {
      return 'Each language should have at least one user phrase'
    }

    return false
  }

  const sendRequest = newIntent => {
    setLoading(true)

    if (modalIntentId == null) {
      createNewIntent(newIntent)
        .then(intent => {
          onClose()
          if (onSelect) onSelect({ label: intent.name, value: intent.id })
          setLoading(false)
        })
        .then(() => {
          if (trainUserPhrase) {
            return trainIntent(trainUserPhrase, activeBot.id)
          }
        })
        .then(() => callback && callback())
    } else {
      newIntent.id = modalIntentId
      updateIntent(newIntent)
        .then(() => {
          onClose()
          setLoading(false)
        })
        .then(() => {
          if (trainUserPhrase) {
            return trainIntent(trainUserPhrase, activeBot.id)
          }
        })
        .then(() => callback && callback())
    }
  }

  return (
    <ModalTemplate open={open} title={loading ? '' : getModalTitle()} onClose={onClose} onSave={handleSaveIntent}>
      {loading ? (
        <S.LoaderScreen>
          <LoaderSmall showLoader={true} />
        </S.LoaderScreen>
      ) : (
        <S.Body>
          <S.Paragraph>{isFaq ? 'FAQ name' : 'Intent name'}</S.Paragraph>
          <Input
            placeholder="Type name"
            value={intentName || ''}
            onChange={event => setIntentName(event.target.value)}
          />
          <S.Paragraph>Questions</S.Paragraph>
          {activeLanguages.map(language => (
            <UserSaysTab
              key={language.id}
              language={language}
              expanded={expanded}
              userSays={userSays}
              setUserSays={setUserSays}
              trainingText={trainUserPhrase?.language?.shortName === language.shortName && trainUserPhrase?.userSay}
              onChange={() => setExpanded(language.shortName)}
            />
          ))}
          {isFaq && <S.Paragraph>Answers</S.Paragraph>}
          {isFaq && <AtomSelect value={selectedAtom} onChange={({ label }) => setSelectedAtom(label)} />}
        </S.Body>
      )}
    </ModalTemplate>
  )
}
