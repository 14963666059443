import styled from 'styled-components'
import { RIBBON_BLUE, SPUN_PEARL_GREY, WHITE } from 'constants/colors'

const Title = styled.div`
  height: 32px;
  color: ${SPUN_PEARL_GREY};
  padding: 8px 12px;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
`

const LoadMoreButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoadMoreButton = styled.button`
  font-size: 12px;
  border: 1px solid ${RIBBON_BLUE};
  color: ${RIBBON_BLUE};
  background: ${WHITE};
  border-radius: 10px;
  padding: 4px 12px;
  box-shadow: none;
  margin: 10px 0px;
  &:hover {
    background: ${WHITE};
  }
`

export { Title, LoadMoreButtonWrapper, LoadMoreButton }
