import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import {ContactsTableHeader} from './header/ContactsTableHeader'
import ContactsTableBody from './body/ContactsTableBody'
import ContactsTableTotalCount from './totalCount/ContactsTableTotalCount'
import {ContactsTablePagination} from './pagination/ContactsTablePagination'
import {getPagingContacts} from '../../api/contactsApi'
import {updateContactsPage} from '../../actions/contactsPageAction'
import classes from './styles.module.scss'

interface ContactsTableProps {
  contactsSize: number
  contactsPage: number
  match: any
}

const ContactsTable: React.FC<ContactsTableProps> = ({
  contactsSize,
  contactsPage,
  match
}) => {

  const handleChangePage = (event, page) => {
    updateContactsPage(page)
    getPagingContacts(page, match.params.botId)
  }

  const rowsPerPage = 20
  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <ContactsTableTotalCount/>
        <ContactsTableHeader/>
        <ContactsTableBody/>
      </div>
      <ContactsTablePagination
        count={contactsSize || 0}
        rowsPerPage={rowsPerPage}
        page={contactsPage || 0}
        onChangePage={handleChangePage}
      />
    </Paper>
  )
}

const mapStateToProps = state => ({
  contactsSize: state.contactsSize,
  contactsPage: state.contactsPage
})

export default withRouter(connect(mapStateToProps)(ContactsTable))
