import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles } from './styles'
import { CopyIcon } from '../../../../uiKit/icons/Icons'
import { copyValueToClipboard } from '../../../../helpers/copyValueToClipboard'

const UserWebsite = props => {
  const { classes, url } = props

  return (
    <div className={classes.linkWrap}>
      <a href={url} className={classes.link} id="copy_data_id" target="_blank" rel="noreferrer">
        {url}
      </a>
      <div
        onClick={() => copyValueToClipboard(url)}
        className={classes.copyIcon}>
        <CopyIcon color={'#1658F3'} width={16} />
      </div>
    </div>
  )
}
UserWebsite.propTypes = {
  classes: PropTypes.object,
  url: PropTypes.string,
}

export default withStyles(styles)(UserWebsite)
