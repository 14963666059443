import {UPDATE_CONTACTS_PAGE} from '../actions/contactsPageAction'

export const contactsPage = (state = null, action) => {
  switch (action.type) {
    case UPDATE_CONTACTS_PAGE:
      return action.contactsPage
    default:
      return state
  }
}
