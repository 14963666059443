import React from 'react'
import classes from './styles.module.scss'

interface CustomButtonProps {
  text: string
  onBtnClick: (e) => void
  disabled?: boolean
  fulled?: boolean
}

export const CustomButton: React.FC<CustomButtonProps> = ({text, onBtnClick, disabled, fulled}) => {

  return (
    <div
      className={disabled ? classes.buttonDisabledWrapper :
        fulled ? classes.buttonFulledWrapper : classes.buttonWrapper}
      onClick={e => {
        return !(disabled || fulled) && onBtnClick(e)
      }}>
      <span className={disabled ? classes.textDisabled : classes.text}>
        {text}
      </span>
    </div>
  )
}
