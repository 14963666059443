import {CLEAR_RESULTS_DELIVERY_INFO, SAVE_RESULTS_DELIVERY_INFO} from '../actions/resultsDeliveryInfoAction'

export const resultsDeliveryInfo = (state = null, action) => {
  switch (action.type) {
    case SAVE_RESULTS_DELIVERY_INFO:
      return action.resultsDeliveryInfo
    case CLEAR_RESULTS_DELIVERY_INFO:
      return null
    default:
      return state
  }
}
