import styled from 'styled-components'
import { MALACHITE_GREEN, RIBBON_BLUE, SPUN_PEARL_GREY, WHITE } from 'constants/colors'

const TrainButton = styled.button<{ isTrained: boolean; isDefault: boolean }>`
  border: 1px solid
    ${({ isTrained, isDefault }) => {
    if (isTrained) return MALACHITE_GREEN
    if (isDefault) return SPUN_PEARL_GREY
    return RIBBON_BLUE
  }};
  color: ${({ isDefault }) => (isDefault ? SPUN_PEARL_GREY : RIBBON_BLUE)};
  background: ${WHITE};
  border-radius: 10px;
  padding: 8px 16px;
  box-shadow: none;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  &:hover {
    background: ${WHITE};
  }
`

const TrainedIcon = styled.div`
  background: ${MALACHITE_GREEN};
  border: 1px solid ${MALACHITE_GREEN};
  border-radius: 10px;
  padding: 10px 34px;
  box-shadow: none;
  line-height: 1.4em;
  font-size: 0.875rem;
  min-height: 36px;
`

export { TrainButton, TrainedIcon }
