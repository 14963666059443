import React, {FC, useEffect, useState} from 'react'
import Switch from 'uiKit/Switch'
import { deepCopyFunction } from '../../../../../helpers/deepCopyFunction'
import {
  TextTogglerContainer
} from './TextToggler.styles'

interface MessageType {
  senderAction: string
  tempId?: string
  type: string
}

interface Props {
  message: MessageType
  updateMessage: (message: MessageType) => void
  onDelete: () => void
}

const TextToggler: FC<Props> = ({
  message,
  updateMessage,
  onDelete
}) => {
  const isTextInputEnable = message.senderAction === 'input_on'
  const [isChecked, setIsChecked] = useState(isTextInputEnable)

  const onChangeHandler = () => {
    setIsChecked(prevState => !prevState)
  }

  useEffect(() => {
    const newMessage = deepCopyFunction(message)
    newMessage.senderAction = isChecked ? 'input_on' : 'input_off'
    updateMessage(newMessage)
  }, [isChecked])

  return (
    <TextTogglerContainer>
      <Switch
        checked={isChecked}
        onChange={onChangeHandler}
        label='Enable text input'
      />
      {onDelete && (
        <div className="dlt-msg" onClick={() => onDelete()}>
          <img src="/images/platform/delete.svg" alt="delete" />
        </div>
      )}
    </TextTogglerContainer>
  )
}

export default TextToggler
