import {CLEAR_GT_KIT_INFO, SAVE_GT_KIT_INFO} from '../actions/gtKitInfoAction'

export const gtKitInfo = (state = null, action) => {
  switch (action.type) {
    case SAVE_GT_KIT_INFO:
      return action.gtKitInfo
    case CLEAR_GT_KIT_INFO:
      return null
    default:
      return state
  }
}
