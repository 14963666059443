import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import GridChart from '../GridChart'
import GradientBar from '../GradientBar'
import NoChartData from 'uiKit/NoChartData'
import { noDataText } from '../../constants/chartsTexts'
import { color, gridItemsCount } from '../../constants/channelColors'

import { DateControlContext } from 'contexts/DateControlContext'
import { loadPopularHoursStatistics } from '../../api/dashboard'
import LoaderSmall from 'uiKit/loaders/loaderSmall'

import * as S from './HourlyChart.style'

interface Props {
  popularHoursStatistics: any
  className: string
  botId: string
}

const HourlyChart: React.FC<Props> = ({ popularHoursStatistics: chartData, botId }) => {
  const [loading, setLoading] = useState(false)
  const { startDate, endDate } = useContext(DateControlContext)

  useEffect(() => {
    setLoading(true)
    loadPopularHoursStatistics(botId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .finally(() => setLoading(false))
  }, [endDate])

  const renderComponent = () => {
    if (loading) {
      return (
        <S.LoaderContainer>
          <LoaderSmall showLoader={true}/>
        </S.LoaderContainer>
      )
    } else if (chartData?.averageConversationByTimeOfDay?.length) {
      return (
        <>
          <GridChart
            chartData={chartData}
            maxPerHour={chartData.maxPerHour}
            color={color}
            gridItemsCount={gridItemsCount}
          />
          <GradientBar gridItemsCount={gridItemsCount} color={color} step={chartData.maxPerHour / gridItemsCount} />
        </>
      )
    } else {
      return <NoChartData text={noDataText} />
    }
  }

  return renderComponent()
}

const mapStateToProps = (state: { popularHoursStatistics: any }) => ({
  popularHoursStatistics: state.popularHoursStatistics
})

export default connect(mapStateToProps)(HourlyChart)
