import {BASE_URL} from '../../../configs'
import {alertError, createJsonHeaders, logoutOnRequestOrResponseJson} from '../../../api'
import {saveContactInfo} from '../actions/contactInfoAction'
import {
  ContactInfo,
  GtKitInfo,
  GtKitOption,
  RescheduleResultDeliveryBody,
  ResultsDeliveryInfo
} from './model/ContactInfo'
import {saveResultsDeliveryInfo} from '../actions/resultsDeliveryInfoAction'
import {saveGtKitInfo} from '../actions/gtKitInfoAction'

export const getContactInfo = (botId: number, contactId: number) => {
  return fetch(
    BASE_URL +
        `/bot/${botId}/contact-info/${contactId}/user-info`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET'
    },
  ).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then((json: ContactInfo) => {
        saveContactInfo(json)
        return json
      })
    }
  })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong, please ping support!',
      )
    })
}

export const scheduleResultDelivery = (body: any, botId: number, contactId: number) => {
  return fetch(
    BASE_URL +
        `/bot/${botId}/contact-info/${contactId}/schedule-results-delivery`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    },
  ).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then((json: ResultsDeliveryInfo) => {
        saveResultsDeliveryInfo(json)
        return json
      })
    }
  })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong, please ping support!',
      )
    })
}

export const rescheduleResultDelivery = (body: RescheduleResultDeliveryBody, botId: number, contactId: number): Promise<Response> => {
  return fetch(
    BASE_URL +
    `/bot/${botId}/contact-info/${contactId}/reschedule-results-delivery`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    },
  ).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then((json: ResultsDeliveryInfo) => {
        saveResultsDeliveryInfo(json)
        return json
      })
    }
  }).catch(() => {
    alertError('Sorry but something going wrong, please ping support!')
  })
}

export const getResultsDeliveryInfo = (botId: number, contactId: number) => {
  return fetch(
    BASE_URL +
        `/bot/${botId}/contact-info/${contactId}/results-delivery`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET'
    },
  ).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then((json: ResultsDeliveryInfo) => {
        saveResultsDeliveryInfo(json)
        return json
      })
    }
  })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong, please ping support!',
      )
    })
}

export const getGtKitInfo = (botId: number, contactId: number) => {
  return fetch(
    BASE_URL +
        `/bot/${botId}/contact-info/${contactId}/gt-kit-info`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET'
    },
  ).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then((json: GtKitInfo) => {
        saveGtKitInfo(json)
        return json
      })
    }
  })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong, please ping support!',
      )
    })
}

export const updateGtKitInfo = (botId: number, contactId: number, gtKitOption: GtKitOption) => {
  return fetch(
    BASE_URL +
        `/bot/${botId}/contact-info/${contactId}/update-gt-kit-info`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'POST',
      body: JSON.stringify(gtKitOption)
    },
  ).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then((json: GtKitInfo) => {
        saveGtKitInfo(json)
        return json
      })
    }
  })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong, please ping support!',
      )
    })
}

export const confirmResultsDelivery = (botId: number, contactId: number) => {
  return fetch(
    BASE_URL +
        `/bot/${botId}/contact-info/${contactId}/confirm-results-delivery`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'POST'
    },
  ).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then((json: ResultsDeliveryInfo) => {
        saveResultsDeliveryInfo(json)
        return json
      })
    }
  })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong, please ping support!',
      )
    })
}
