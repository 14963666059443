import React from 'react'
import classes from './styles.module.scss'

interface StatusCellProps {
  statusColor: string
  statusTitle: string
}

export const StatusCell: React.FC<StatusCellProps> = ({statusColor, statusTitle}) => {

  return (
    <div className={classes.statusWrapper}
      style={{backgroundColor: statusColor}}>
      {statusTitle}
    </div>
  )
}
