import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { Collapse } from '@material-ui/core'
import { useSelector } from 'react-redux'

import Reducers from 'reducers'
import { DeleteIcon } from 'uiKit/icons/DeleteIcon'
import Tooltip from 'uiKit/Tooltip'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import LoaderOverlay from 'uiKit/loaders/loaderOverlay'
import { SourceTypeMap } from 'tabs/nlp/constants'
import { getKnowledgeSourcePages } from 'tabs/nlp/api/websiteKnowledgeBase'
import { TrainingStatus } from 'tabs/settings/constants/trainingStatus'

import * as S from './SourcesTable.style'
import TrainingStatusPending from '../TrainingStatus'
import FailedStatus from '../FailedStatus'
import UploadedStatus from '../UploadedStatus'
import { DetectOverflow } from '../DetectOverflow/DetectOverflow'
import { SourcesSubPages } from './SourcesSubPages'
import { EditTextInsert } from '../../EditTextInsert/EditTextInsert'
import { EditKnowledgeBaseReply, TextInsertSource } from '../../KnowledgeBaseReplies/EditKnowledgeBaseReply'
import { DeleteSourceModal } from './DeleteSourceModal'

interface SourcesTableProps {
  sources: any
  setSources?: Dispatch<SetStateAction<any>>
  onDelete: (source: any) => Promise<void>
  onTrain: () => void
  trainingStatus: string
  sourceId?: string | undefined | null
}

export const SourcesTable: FC<SourcesTableProps> = ({
  sources,
  setSources,
  onDelete,
  onTrain,
  trainingStatus,
  sourceId,
}) => {
  const botId: number = useSelector((state: ReturnType<typeof Reducers>) => state.activeBot.id)
  const [loading, setLoading] = useState(false)
  const [subPagesLoading, setSubPagesLoading] = useState(false)
  const [expanded, setExpanded] = useState(null)
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState<{ source: any; isOpen: boolean }>({
    source: null,
    isOpen: false,
  })
  const [knowledgePages, setKnowledgePages] = useState({})
  const [insertTextSource, setInsertTextSource] = useState<TextInsertSource>(null)
  const highlightRef = useRef(null)

  const sourcesPending = useMemo(
    () => sources.filter(source => ['PENDING', 'FAILED', 'NOT_TRAINED'].includes(source.status)),
    [sources],
  )
  const sourcesTrained = useMemo(() => sources.filter(source => source.status === 'READY'), [sources])
  const sourcesFailed = useMemo(() => sources.some(source => source.status === 'FAILED'), [sources])

  const getSourceName = source => {
    const sourceNameToSourceTypeMap = {
      [SourceTypeMap.FILE]: source.originalFilename,
      [SourceTypeMap.TEXT]: source.text,
      [SourceTypeMap.WEBSITE]: source.url,
    }
    return sourceNameToSourceTypeMap[source.type]
  }

  const handleExpand = (sourceId: string) => {
    if (sourceId === expanded) {
      return setExpanded(null)
    }
    setExpanded(sourceId)

    if (knowledgePages[sourceId]) return

    setSubPagesLoading(true)
    getKnowledgeSourcePages(sourceId, botId)
      .then(pages => {
        setKnowledgePages({ ...knowledgePages, [sourceId]: Array.isArray(pages) ? pages : [] })
      })
      .finally(() => setSubPagesLoading(false))
  }

  const handleDelete = source => {
    if (source.isUploaded === false) {
      onDelete(source)
      resetDeleteConfirmationModal()
      return
    }
    setLoading(true)
    onDelete(source).finally(() => {
      setLoading(false)
      resetDeleteConfirmationModal()
    })
  }

  const resetDeleteConfirmationModal = (): void => {
    setDeleteConfirmationModal({ source: null, isOpen: false })
  }

  const handleOpenEditTextInsert = ({ sourceId, text, title }: TextInsertSource): void => {
    setInsertTextSource({ sourceId, title, text })
  }

  useEffect(() => {
    if (highlightRef.current) {
      highlightRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [highlightRef.current])

  return (
    <>
      <S.SourcesTable>
        {!!sourcesPending?.length && (
          <>
            <S.HeaderWrap>
              <S.Header>TO TRAIN</S.Header>
              {trainingStatus === TrainingStatus.PENDING ? (
                <TrainingStatusPending />
              ) : (
                <S.Button onClick={onTrain}>{sourcesFailed ? 'Train again' : 'Start training'}</S.Button>
              )}
            </S.HeaderWrap>
            {sourcesPending.map((source, index) => (
              <S.Row key={source._id} index={index}>
                <div style={{ flex: '1 1 100%', minWidth: 0, display: 'grid' }}>
                  <S.Source sourceType={source.type}>
                    {source.type === SourceTypeMap.TEXT ? (
                      <>
                        <h6>{source.title || 'Untitled'}</h6>
                        {getSourceName(source)}
                      </>
                    ) : (
                      <DetectOverflow>{getSourceName(source)}</DetectOverflow>
                    )}
                  </S.Source>
                </div>
                <S.DeleteWrap>
                  {source.type === SourceTypeMap.FILE && source.status !== 'FAILED' && (
                    <UploadedStatus isUploaded={source.isUploaded} />
                  )}
                  {source.status === 'FAILED' && <FailedStatus />}
                  <S.Delete onClick={() => setDeleteConfirmationModal({ source, isOpen: true })}>
                    <DeleteIcon />
                  </S.Delete>
                </S.DeleteWrap>
              </S.Row>
            ))}
          </>
        )}
        {!!sourcesTrained?.length && (
          <>
            <S.HeaderWrap>
              <S.Header>TRAINED</S.Header>
            </S.HeaderWrap>
            {sourcesTrained.map((source, index) => (
              <React.Fragment key={source._id}>
                <S.Row
                  index={index}
                  needHighlight={source._id === sourceId}
                  ref={source._id === sourceId ? highlightRef : null}>
                  <div style={{ flex: '1 1 100%', minWidth: 0, display: 'grid' }}>
                    <S.Source sourceType={source.type}>
                      {source.type === 'TEXT' ? (
                        <>
                          <h6>{source.title || 'Untitled'}</h6>
                          {getSourceName(source)}
                        </>
                      ) : (
                        <DetectOverflow>{getSourceName(source)}</DetectOverflow>
                      )}
                    </S.Source>
                  </div>
                  <S.DeleteWrap>
                    {source.parsedLinksCount && (
                      <S.Count data-for={source._id} data-tip onClick={() => handleExpand(source._id)}>
                        {source.parsedLinksCount}
                        {expanded === source._id ? <ExpandLess /> : <ExpandMore />}
                      </S.Count>
                    )}
                    <Tooltip id={source._id}>Amount of trained pages</Tooltip>
                    {source.type === SourceTypeMap.TEXT && (
                      <EditTextInsert
                        onClick={() =>
                          handleOpenEditTextInsert({ sourceId: source._id, title: source.title, text: source.text })
                        }
                        wasEdited={false}
                      />
                    )}
                    <S.Delete onClick={() => setDeleteConfirmationModal({ source, isOpen: true })}>
                      <DeleteIcon />
                    </S.Delete>
                  </S.DeleteWrap>
                </S.Row>

                <Collapse in={expanded === source._id}>
                  <S.SourceDetails index={index} expanded={expanded === source._id}>
                    {subPagesLoading && expanded === source._id ? (
                      <LoaderSmall showLoader />
                    ) : (
                      <SourcesSubPages title="List of trained pages" subPages={knowledgePages[source._id] || []} />
                    )}
                  </S.SourceDetails>
                </Collapse>
              </React.Fragment>
            ))}
          </>
        )}
        {trainingStatus === TrainingStatus.PENDING && <S.DisableScreen />}
      </S.SourcesTable>
      {insertTextSource?.sourceId && (
        <EditKnowledgeBaseReply
          botId={botId}
          isOpen
          onClose={() => setInsertTextSource(null)}
          setSources={setSources}
          sourceId={insertTextSource.sourceId}
          inputTitle={insertTextSource.title || 'Untitled'}
          textAreaContent={insertTextSource.text || ''}
        />
      )}
      {deleteConfirmationModal.isOpen && (
        <DeleteSourceModal
          open
          onClose={resetDeleteConfirmationModal}
          onDelete={() => handleDelete(deleteConfirmationModal.source)}
        />
      )}
      {loading && <LoaderOverlay showLoader={loading} />}
    </>
  )
}
