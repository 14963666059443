import styled from 'styled-components'

const UploadedStatus = styled.div<{ isUploaded: boolean }>`
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: ${({ isUploaded }) => (isUploaded === false ? '#D4E1FF' : '#E5F8E2')};
  color: ${({ isUploaded }) => (isUploaded === false ? '#1658F3' : '#20B038')};
  display: flex;
  gap: 6px;
  align-items: center;
`

export { UploadedStatus }
