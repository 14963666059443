import { store } from '../../../index'
import {GtKitInfo} from '../api/model/ContactInfo'

export const SAVE_GT_KIT_INFO = 'SAVE_GT_KIT_INFO'
export const CLEAR_GT_KIT_INFO = 'CLEAR_GT_KIT_INFO'

export const saveGtKitInfo = (gtKitInfo: GtKitInfo) => {
  store.dispatch({
    type: SAVE_GT_KIT_INFO,
    gtKitInfo,
  })
}

export const clearGtKitInfo = () => {
  store.dispatch({
    type: CLEAR_GT_KIT_INFO,
  })
}
