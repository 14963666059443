import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {InfoFieldTitle} from '../../../common/InfoFieldTitle'
import {InsuranceCardPhotoPlaceholder} from '../photoPlaceholder/InsuranceCardPhotoPlaceholder'
import classes from './styles.module.scss'

interface InsuranceCardPhotosProps {
  insuranceCardPhotos?: string[]
}

const InsuranceCardPhotos: React.FC<InsuranceCardPhotosProps> = ({insuranceCardPhotos}) => {

  return (
    <div className={classes.wrapper}>
      <InfoFieldTitle
        title={'Insurance card photos'}
      />
      <div className={classes.photoWrapper}>
        <InsuranceCardPhotoPlaceholder photoUrl={insuranceCardPhotos ? insuranceCardPhotos[0] : undefined}/>
        <div className={classes.secondPlaceholder}>
          <InsuranceCardPhotoPlaceholder photoUrl={insuranceCardPhotos ? insuranceCardPhotos[1] : undefined}/>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  insuranceCardPhotos: state.contactInfo.insuranceCardPhotos
})

export default withRouter(connect(mapStateToProps)(InsuranceCardPhotos))
