import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import BackToContacts from './components/backToContacts/BackToContacts'
import {UserInfoContent} from './components/userInfoContent/UserInfoContent'
import {getContactInfo, getGtKitInfo, getResultsDeliveryInfo} from './api/contactInfoApi'
import Loader from '../../uiKit/loaders/loader'
import {ContactInfo, GtKitInfo, ResultsDeliveryInfo} from './api/model/ContactInfo'
import classes from './styles.module.scss'

interface ContactPageProps {
  match: any
  contactInfo?: ContactInfo
  gtKitInfo?: GtKitInfo
  resultsDeliveryInfo?: ResultsDeliveryInfo
}

export const ContactPage: React.FC<ContactPageProps> = ({match, contactInfo, gtKitInfo, resultsDeliveryInfo}) => {

  useEffect(() => {
    getContactInfo(match.params.botId, match.params.contactId)
    getGtKitInfo(match.params.botId, match.params.contactId)
    getResultsDeliveryInfo(match.params.botId, match.params.contactId)
  }, [match.params.botId, match.params.contactId])

  if (contactInfo && gtKitInfo && resultsDeliveryInfo) {
    return (
      <div className={classes.contactInfoWrapper}>
        <BackToContacts/>
        <div className={classes.userInfoText}>
              User info
        </div>
        <UserInfoContent match={match}/>
      </div>
    )
  } else {
    return <Loader/>
  }
}

const mapStateToProps = state => ({
  contactInfo: state.contactInfo,
    gtKitInfo: state.gtKitInfo,
    resultsDeliveryInfo: state.resultsDeliveryInfo
})

export default withRouter(connect(mapStateToProps)(ContactPage))
