import { FIORD_BLUE } from 'constants/colors'

export const styles = () => ({
  table: {
    width: 'calc(100% + 80px)',
    margin: '0 -40px',
  },
  head: {
    height: 32,
    borderTop: 'rgb(235, 235, 235) 1px solid',
    borderBottom: 'rgb(235, 235, 235) 1px solid',
  },
  headRow: {
    height: 32,
  },
  body: {
    '& td': {
      color: '#354052 !important',
      fontSize: 14,
      fontFamily: 'Lato, sans-serif',
    },
  },
  imageCell: {
    '& img': {
      width: 40,
      height: 40,
      margin: '12px 0',
      display: 'block',
      borderRadius: '50%',
      overflow: 'hidden',
    },
  },
  languages: {
    margin: 0,

    '& span': {
      display: 'block',
      fontSize: 12,
      color: FIORD_BLUE,
      opacity: '0.5',
    },
  },
  pageModalPaper: {
    padding: '24px',
    maxWidth: 360,
    width: '100%',

    '& h2': {
      maxWidth: 288,
      textAlign: 'start'
    },
  },
  text: {
    fontSize: 14,
    fontFamily: 'Lato, sans-serif',
    color: '#616581',
    textAlign: 'center',
  },
})

// TODO find a better way to add this styles
export const cellStyles = {
  image: {
    width: '76px',
    padding: '0 12px 0 24px',
  },
  name: {
    width: '30%',
    padding: '0 24px 0 0',
  },
  languages: {
    width: '32%',
    padding: '0 24px 0 0',
  },
  connectedBy: {
    width: '32%',
    padding: 0,
  },
  menu: {
    width: 48,
    padding: '0 24px 0 0',
  }
}
