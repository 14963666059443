/* eslint-disable */
import React from 'react'

export const DoneIcon = () => {
  return (
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8047 0.52827C12.0651 0.788619 12.0651 1.21073 11.8047 1.47108L4.4714 8.80441C4.21106 9.06476 3.78894 9.06476 3.5286 8.80441L0.195262 5.47108C-0.0650874 5.21073 -0.0650874 4.78862 0.195262 4.52827C0.455612 4.26792 0.877722 4.26792 1.13807 4.52827L4 7.3902L10.8619 0.52827C11.1223 0.26792 11.5444 0.26792 11.8047 0.52827Z"
        fill="#20B038"
      />
    </svg>
  )
}
