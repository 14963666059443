import React from 'react'
import { withStyles } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import PropTypes from 'prop-types'

import { styles } from './styles'
import { getModalStyle } from '../../../../uiKit/Modal'
import Close from '@material-ui/icons/Close'
import {
  apiCall,
  attachmentMessage,
  availableDefaultMessages,
  galleryMessage,
  randomRedirectInitialState,
  saveUserInput,
  setAttributeMessage,
  setTextToggle,
  smartRedirect,
} from '../messages/AvailableMessages'
import uuidv1 from 'uuid/v1'
import { SaveUserInputIcon } from '../../../../uiKit/icons/SaveUserInputIcon'
import { TagIcon } from '../../../../uiKit/icons/TagIcon'
import { GalleryIcon } from '../../../../uiKit/icons/GalleryIcon'
import { AttachmentMessageIcon } from '../../../../uiKit/icons/AttachmentMessageIcon'
import { RandomRedirectIcon } from '../../../../uiKit/icons/RandomRedirectIcon'
import { TextTogglerIcon } from '../../../../uiKit/icons/TextTogglerIcon'
import ActionModalItem from '../ActionModalItem/ActionModalItem'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import { alertError } from '../../../../api'
import {
  API_CALL_TYPE,
  CONDITIONAL_REDIRECT_TYPE,
  RANDOM_REDIRECT_TYPE,
  SAVE_USER_INPUT_TYPE,
  SET_ATTRIBUTE_TYPE,
} from '../../constants/messageTypes'

const AddActionModal = props => {
  const { classes, open, atom, updateAtom, onClose } = props

  const onClickHandler = message => {
    if (message.type === CONDITIONAL_REDIRECT_TYPE) {
      addSmartRedirect()
      return
    } else if (message.type === SET_ATTRIBUTE_TYPE) {
      addSetAttribute()
      return
    } else if (message.type === API_CALL_TYPE) {
      addAPICall()
      return
    }
    handleCreateNewMessage(message.newMessage)
  }

  const handleCreateNewMessage = newMessage => {
    const newAtom = { ...atom }
    if (!newAtom.messages) newAtom.messages = []
    newMessage['tempId'] = uuidv1()
    newAtom.messages.push({ ...newMessage })
    updateAtom(newAtom)
    onClose()
  }

  const addSetAttribute = () => {
    const newAtom = { ...atom }
    if (!newAtom.messages) newAtom.messages = []
    if (!newAtom.messages.some(message => message.type === SET_ATTRIBUTE_TYPE)) {
      const newMessage = deepCopyFunction(setAttributeMessage)
      newMessage['tempId'] = uuidv1()
      newAtom.messages.push({ ...newMessage })
      updateAtom(newAtom)
    }
    onClose()
  }

  const addTextToggle = () => {
    const newAtom = { ...atom }
    if (!newAtom.messages) newAtom.messages = []
    const newMessage = deepCopyFunction(setTextToggle)
    newMessage['tempId'] = uuidv1()
    newAtom.messages.push({ ...newMessage })
    updateAtom(newAtom)
    onClose()
  }

  const addSaveUserInput = () => {
    const newAtom = { ...atom }
    if (!newAtom.messages) newAtom.messages = []
    if (
      !newAtom.messages.some(
        message =>
          message.type === SAVE_USER_INPUT_TYPE ||
          message.type === RANDOM_REDIRECT_TYPE ||
          message.type === CONDITIONAL_REDIRECT_TYPE,
      )
    ) {
      const newMessage = deepCopyFunction(saveUserInput)
      newMessage['tempId'] = uuidv1()
      newAtom.messages.push(JSON.parse(JSON.stringify(newMessage)))
      updateAtom(newAtom)
    } else {
      alertError('Atom can contain only one of these elements: Smart Redirect atom, Random redirect or Save User Input')
    }
    onClose()
  }

  const addTags = () => {
    const newAtom = { ...atom }
    newAtom.tags = []
    updateAtom(newAtom)
    onClose()
  }

  const addSmartRedirect = () => {
    const newAtom = { ...atom }
    if (!newAtom.messages) newAtom.messages = []
    if (
      !newAtom.messages.some(
        message =>
          message.type === RANDOM_REDIRECT_TYPE ||
          message.type === CONDITIONAL_REDIRECT_TYPE ||
          message.type === SAVE_USER_INPUT_TYPE,
      )
    ) {
      const newMessage = deepCopyFunction(smartRedirect)
      newMessage['tempId'] = uuidv1()
      newAtom.messages.push(JSON.parse(JSON.stringify(newMessage)))
      updateAtom(newAtom)
    } else {
      alertError('Atom can contain only one of these elements: Smart Redirect atom, Random redirect or Save User Input')
    }
    onClose()
  }

  const addRandomRedirect = () => {
    const newAtom = deepCopyFunction(atom)

    if (!newAtom.messages) newAtom.messages = []
    if (
      !newAtom.messages.some(
        message =>
          message.type === RANDOM_REDIRECT_TYPE ||
          message.type === CONDITIONAL_REDIRECT_TYPE ||
          message.type === SAVE_USER_INPUT_TYPE,
      )
    ) {
      const newMessage = deepCopyFunction(randomRedirectInitialState)
      newMessage['tempId'] = uuidv1()
      newAtom.messages.push(deepCopyFunction(newMessage))
      updateAtom(newAtom)
    } else {
      alertError('Atom can contain only one of these elements: Smart Redirect atom, Random redirect or Save User Input')
    }
    onClose()
  }

  const addAPICall = () => {
    const newAtom = { ...atom }
    if (!newAtom.messages) newAtom.messages = []
    const newMessage = deepCopyFunction(apiCall)
    newMessage['tempId'] = uuidv1()
    newAtom.messages.push({ ...newMessage })
    updateAtom(newAtom)
    onClose()
  }

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      style={{ overflowY: 'auto' }}
      onClose={onClose}>
      <div style={getModalStyle()} className={classes.paper}>
        <div className={classes.header}>
          <Close className={classes.btnClose} onClick={onClose} />
          <h2 className={classes.title}>Add an action</h2>
        </div>
        <div className={classes.body}>
          <div className={classes.section}>
            {availableDefaultMessages.map((message, index) => (
              <ActionModalItem
                key={index}
                icon={message.iconLarge}
                name={message.name}
                description={message.description}
                onClick={() => onClickHandler(message)}
              />
            ))}
            <ActionModalItem
              icon={<TextTogglerIcon />}
              name={'Text toggler'}
              description={'Enable text input'}
              onClick={addTextToggle}
            />
          </div>
          <div className={classes.section}>
            <ActionModalItem
              icon={<GalleryIcon width="24" height="24" color="#5A98F7" />}
              name={'Gallery'}
              description={'Send a carousel of options in chat'}
              onClick={() => handleCreateNewMessage(galleryMessage.newMessage)}
            />
            <ActionModalItem
              icon={<AttachmentMessageIcon width="24" height="24" color="#5A98F7" />}
              name={'Attachment'}
              description={'Send an image or GIF in chat'}
              onClick={() => handleCreateNewMessage(attachmentMessage.newMessage)}
            />
            <ActionModalItem
              icon={<SaveUserInputIcon />}
              name={'Save user input'}
              description={'Set validation and save users responses to user attributes'}
              onClick={addSaveUserInput}
            />
            <ActionModalItem icon={<TagIcon />} name={'Add tags'} description={'Add tags to users'} onClick={addTags} />
            <ActionModalItem
              icon={<RandomRedirectIcon />}
              name={'Random redirect'}
              description={'Randomly redirect user to another atom'}
              onClick={addRandomRedirect}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
AddActionModal.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(AddActionModal)
