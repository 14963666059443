import React from 'react'
import classes from './styles.module.scss'

interface InfoFieldValueProps {
    value: string
    link: boolean
}

export const InfoFieldValue: React.FC<InfoFieldValueProps> = ({value, link}) => {
  return (<div className={classes.infoFiledValue}>
    {link ? <a href={value}>{value}</a> : value}
  </div>
  )
}
