import React from 'react'

export const PdfFileIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        /* eslint-disable-next-line max-len */
        d="M5.25 19.5H6.54V18.15H7.125C8.055 18.15 8.835 17.475 8.835 16.56C8.835 15.645 8.085 14.985 7.125 14.985H5.25V19.5ZM6.54 17.115V16.035H6.99C7.29 16.035 7.545 16.23 7.545 16.56C7.545 16.89 7.305 17.1 6.99 17.1H6.54V17.115ZM10.035 19.5H11.82C13.32 19.5 14.25 18.615 14.25 17.22C14.25 15.915 13.32 15 11.82 15H10.035V19.5ZM11.325 18.435V16.05H11.76C12.255 16.05 12.93 16.29 12.93 17.22C12.93 18.195 12.255 18.435 11.76 18.435H11.325ZM15.75 19.5H17.04V17.895H18.63V16.86H17.04V16.05H18.855V15.015H15.765V19.515L15.75 19.5Z"
        fill="#5A98F7"/>
      <path
        /* eslint-disable-next-line max-len */
        d="M19.5 24H4.5C3.255 24 2.25 22.995 2.25 21.75V2.25C2.25 1.005 3.255 0 4.5 0H15.135C15.735 0 16.305 0.24 16.725 0.66L21.09 5.025C21.51 5.445 21.75 6.015 21.75 6.615V21.75C21.75 22.995 20.745 24 19.5 24ZM4.5 1.5C4.08 1.5 3.75 1.83 3.75 2.25V21.75C3.75 22.17 4.08 22.5 4.5 22.5H19.5C19.92 22.5 20.25 22.17 20.25 21.75V6.615C20.2512 6.51664 20.2318 6.41911 20.1931 6.32869C20.1543 6.23828 20.0971 6.15697 20.025 6.09L15.66 1.725C15.5206 1.58503 15.3325 1.50441 15.135 1.5H4.5Z"
        fill="#5A98F7"/>
      <path
        /* eslint-disable-next-line max-len */
        d="M20.82 7.49999H16.455C15.24 7.49999 14.25 6.50999 14.25 5.29499V0.929993C14.25 0.509993 14.58 0.179993 15 0.179993C15.42 0.179993 15.75 0.509993 15.75 0.929993V5.29499C15.75 5.68499 16.065 5.99999 16.455 5.99999H20.82C21.24 5.99999 21.57 6.32999 21.57 6.74999C21.57 7.16999 21.24 7.49999 20.82 7.49999Z"
        fill="#5A98F7"/>
    </svg>
  )
}
