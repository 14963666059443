import styled from 'styled-components'
import { RIBBON_BLUE } from 'constants/colors'

const Container = styled.div<{ index: number }>`
  display: flex;
  align-items: center;
  gap: 4px;
`

const Text = styled.div`
  color: ${RIBBON_BLUE};
  font-size: 16px;
  font-weight: 700;
`

export { Container, Text }
