import React from 'react'
import {
  DeleteReactionButton,
  ReactionBarContainer,
  ReactionBarText,
  ReactionBarTooltip,
  ReactionBarTooltipIcons,
  ReactionBarTooltipText,
  ReactionIconContainer,
} from './ReactionBar.styles'
import { TrashIcon } from '../icons/Icons'
import { SPUN_PEARL_GREY } from 'constants/colors'

interface Props {
  onDelete: () => void
}

const ReactionBar: React.FC<Props> = ({ onDelete }) => {

  return (
    <ReactionBarContainer>
      <ReactionBarText>Show reactions for message above</ReactionBarText>
      <ReactionBarTooltip>
        <ReactionBarTooltipText>Was this helpful?</ReactionBarTooltipText>
        <ReactionBarTooltipIcons>
          <ReactionIconContainer>👍</ReactionIconContainer>
          <ReactionIconContainer>👎</ReactionIconContainer>
        </ReactionBarTooltipIcons>
      </ReactionBarTooltip>
      <DeleteReactionButton onClick={onDelete}>
        <TrashIcon width="20px" height="20px" color={SPUN_PEARL_GREY} />
      </DeleteReactionButton>
    </ReactionBarContainer>
  )
}

export default ReactionBar
