import styled from 'styled-components'
import { FIORD_BLUE, SELAGO_GRAY } from 'constants/colors'

const Container = styled.div`
  width: 100%;
  background-color: white;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
  margin-top: 32px;
  margin-bottom: 16px;
  position: relative;
`

const Title = styled.p`
  margin: 0;
  color: ${FIORD_BLUE};
  font-size: 14px;
  font-weight: 700;
`

const Form = styled.div`
  display: flex;
  margin-top: 8px;
  width: 100%;
  gap: 24px;
  position: relative;
`

const Icon = styled.span`
  position: absolute;
  right: 24px;
  top: 10px;
`

const DisableScreen = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  bottom: 0;
  opacity: 0.3;
  background-color: ${SELAGO_GRAY};
`

export { Container, Title, Form, Icon, DisableScreen }
