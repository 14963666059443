import React, { Dispatch, FC, MutableRefObject, SetStateAction, useEffect } from 'react'
import { jsPDF } from 'jspdf'
import * as htmlToImage from 'html-to-image'
import FamilyTreePage from '../FamilyTreePage'
import { ModalContainer } from './FamilyTreeModal.styles'
import { sendFamilyTreeFile } from '../helpers/familyTreeRequests'
import {getContactInfo} from '../../../api/contactInfoApi'

export interface FamilyTreeSettingsInterface {
  isOpen: boolean
  isLoading: boolean
}

interface Props {
  reactFlowRef: MutableRefObject<HTMLDivElement>
  setFamilyTreeSettings: Dispatch<SetStateAction<FamilyTreeSettingsInterface>>
  chatId: string
  match: any
}

const FamilyTreeModal: FC<Props> = ({
  reactFlowRef,
  setFamilyTreeSettings,
  chatId,
  match
}) => {

  const familyTreePDFProcessor = () => (
    setTimeout(() => {
      const element = reactFlowRef.current
      const scrollHeight = element.scrollHeight
      const scrollWidth = element.scrollWidth
      htmlToImage.toJpeg(element, { pixelRatio: 1 }).then(dataUrl => {
        const pdf = new jsPDF({
          orientation: 'l',
          unit: 'pt',
          format: [scrollWidth, scrollHeight]
        })
        const img = new Image()
        img.src = dataUrl

        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = pdf.internal.pageSize.getHeight()

        pdf.addImage(img, 'PNG', 0, 0, pdfWidth, pdfHeight)
        pdf.setDocumentProperties({ title: 'My Canсer Family History' })

        const pdfBlob = pdf.output('blob')
        sendPDFFile(pdfBlob)
      })
      setFamilyTreeSettings((prevState) => {
        return {
          ...prevState,
          isOpen: true //updated
        }
      })
    }, 100)
  )

  const sendPDFFile = (file) => {
    const formData = new FormData()
    formData.append('file', file, 'tree.pdf')

    sendFamilyTreeFile(chatId, formData).then((res) => {
      if (res) {
        getContactInfo(match.params.botId, match.params.contactId)
        setFamilyTreeSettings((prevState) => {
          return {
            ...prevState,
            isOpen: false, //updated
            isLoading: false
          }
        })
      }
    })
  }

  useEffect(() => {
    return () => clearTimeout(familyTreePDFProcessor())
  }, [])

  return (
    <ModalContainer>
      <FamilyTreePage
        reactFlowRef={reactFlowRef}
        familyTreePDFProcessor={familyTreePDFProcessor}
        chatId={chatId}
      />
    </ModalContainer>
  )
}

export default FamilyTreeModal
