import {CLEAR_CONTACT_INFO, SAVE_CONTACT_INFO} from '../actions/contactInfoAction'

export const contactInfo = (state = null, action) => {
  switch (action.type) {
    case SAVE_CONTACT_INFO:
      return action.contactInfo
    case CLEAR_CONTACT_INFO:
      return null
    default:
      return state
  }
}
