import styled from 'styled-components'
import { COMET_GREY, CORNFLOWER_BLUE, MISCHKA_GRAY, WHITE } from 'constants/colors'

const CustomRadioButton = styled.div`
  display: flex;
  cursor: pointer;
  max-height: 45px;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  width: 200px;
  border: 1px solid ${MISCHKA_GRAY};
`

const Tab = styled.div<{ isSelected: boolean }>`
  background: ${({ isSelected }) => (isSelected ? CORNFLOWER_BLUE : WHITE)};
  color: ${({ isSelected }) => (isSelected ? WHITE : COMET_GREY)};
  padding: 13px 12px;
  max-height: 100%;
  font-size: 14px;
  flex-grow: 1;
  flex-basis: 0;
  white-space: nowrap;
  display: flex;
  justify-content: center;
`

export { CustomRadioButton, Tab }
