import {
  DECEASED_AFFECTED_FEMALE,
  DECEASED_AFFECTED_MALE,
  DECEASED_FEMALE,
  DECEASED_MALE,
  LIVING_AFFECTED_FEMALE,
  LIVING_AFFECTED_MALE,
  LIVING_UNAFFECTED_FEMALE,
  LIVING_UNAFFECTED_MALE,
  UNKNOWN_LIVING_DECEASED_STATUS
} from '../SvgTypes/svgIcons'

export const legendItems = [
  {
    title: 'Living Unaffected Female',
    icon: LIVING_UNAFFECTED_FEMALE
  },
  {
    title: 'Living Unaffected Male',
    icon: LIVING_UNAFFECTED_MALE
  },
  {
    title: 'Deceased Female',
    icon: DECEASED_FEMALE
  },
  {
    title: 'Deceased Male',
    icon: DECEASED_MALE
  },
  {
    title: 'Unknown Living/Deceased Status',
    icon: UNKNOWN_LIVING_DECEASED_STATUS
  },
  {
    title: 'Living Affected Female',
    icon: LIVING_AFFECTED_FEMALE
  },
  {
    title: 'Living Affected Male',
    icon: LIVING_AFFECTED_MALE
  },
  {
    title: 'Deceased Affected Female',
    icon: DECEASED_AFFECTED_FEMALE
  },
  {
    title: 'Deceased Affected Male',
    icon: DECEASED_AFFECTED_MALE
  }
]
