import styled from 'styled-components'
import { PERMISSION_RED, SPUN_PEARL_GREY, WHITE } from 'constants/colors'

const ErrorAlert = styled.p`
  color: ${PERMISSION_RED};
  margin-bottom: -10px;
  font-size: 12px;
  position: absolute;
  z-index: 2;
  background-color: ${WHITE};
`

const MaxLengthAlert = styled.p`
  color: ${SPUN_PEARL_GREY};
  margin-bottom: -10px;
  font-size: 12px;
  position: absolute;
  z-index: 1;
  background-color: ${WHITE};
`

const EllipsisText = styled.div`
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
`

const IconWrap = styled.span`
  cursor: pointer;
  padding-right: 6px;
`

export { ErrorAlert, MaxLengthAlert, EllipsisText, IconWrap }
