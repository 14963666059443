import {ADD_CONTACT, CLEAR_CONTACTS, SAVE_CONTACTS} from '../actions/contactsAction'

export const contacts = (state = null, action) => {
  switch (action.type) {
    case SAVE_CONTACTS:
      return action.contacts
    case ADD_CONTACT:
      if (state) {
        const contact = [...state]
        contact.unshift(action.contact)
        return contact
      } else {
        return state
      }
    case CLEAR_CONTACTS:
      return null
    default:
      return state
  }
}
