import { createMuiTheme } from '@material-ui/core/'

export const theme = createMuiTheme({
  colors: {
    primary: '#0152A8',
    secondary: '#5197BD',
    additional: '#6AB9E5',
    additionalInactive: '#D0D8DD',
    additionalGray: '#788AA3',
    mainButtonHoverColor: '#1865B8',
    mainButtonFocusColor: '#08488C',
    mainButtonDisabledColor: '#D0D8DD',
    secondaryButtonDefaultColor: '#6AB9E5',
    secondaryButtonHoverColor: '#1593DC',
    secondaryButtonFocusColor: '#5197BD',
    additionalColor: '#6AB9E5',
    additionalText: '#002D5E',
    additionalBackgroundWhite: '#F4F5F9',
    systemError: '#E07A5F',
    systemWarning: '#F2CC8F',
    systemSuccess: '#73C39D',
    errorMessage: '#FF614C',
    widgetMessageColor: '#5197BD',
    additionalWidgetMessageColor: '#E1F2FF',
    blockedGeneralInfoColor: '#F5F7F8',
    singleSelectButtonTextColor: '#447D9C',
    ghostButtonActiveBorderColor: '#175C82',
    ghostButtonActiveTextColor: '#1C6891',
    modalBorder: '#C3D2EA',
    userInputBorder: '#C0E2FB',
    disabledMenuBorder: '#6A92BD',
    userInputPlaceholder: '#A3A3A3',
    white: '#ffffff',
    headerBorderColor: '#E2E6E7',
    gray600: '#4D4D4D',
    familyTreeBackgroundColor: '#B0D7FF',
    lightPurple: '#F8E5E0',
    lightBlue: '#DEEEF8',
    nodeBoxShadow: 'rgba(187, 196, 209, 0.80)',
    nodeContainerBorder: '#111E2B',
    personalCustomNodeBackgroundColor: '#EBB09F',
    disabledMenuItemFont: '#5E6D77',
    disabledMenuItem: '#BECDD6',
    activeMenuItem: '#01458D',
    scrollColor: '#9FA2B5',
    headerBackground: '#E9F5FF',
    tooltipBackgroundColor: '#393939',
    transparent: 'rgba(0, 0, 0, 0)'
  },
  palette: {
    primary: {
      light: '#5A98F7',
      medium: '#1146C2',
      main: '#1658F3',
      dark: '#1345BA',
      grayColor: '#D7D8DF',
      text: '#616581',
      contrastText: '#fff',
      azure: '#8EB0F8',
      iconsGray: '#D7D8DF',
      semiAzure: '#D5E5FD',
      headerText: '#3A3F62',
      background: '#F7FAFE',
    },
    text: {
      header: '#3A3F62',
      gray: '#B0B2C0',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    default: {
      gray: '#C7CAD6',
    },
    alerts: {
      success: '#11C314',
      error: '#FF624C',
      warning: '#FF974C'
    },
    pieChartColors: {
      chatbot: '#1658F3',
      support: '#F3B116',
    },
  },
})
