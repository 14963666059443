import React from 'react'

export const UploadFileIcon = () => {
  return (
    <svg width="47" height="38" viewBox="0 0 47 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        /* eslint-disable-next-line max-len */
        d="M17.3633 0.0164108C19.9313 -0.0782018 22.4872 0.41056 24.839 1.44595C27.1909 2.48134 29.2774 4.03642 30.9417 5.99427C32.4376 7.75398 33.5575 9.7979 34.2358 12H35.9999C38.4438 12.0016 40.8183 12.817 42.7473 14.3176C44.6763 15.8181 46.0506 17.9183 46.6534 20.2867C47.2562 22.6551 47.0532 25.1568 46.0763 27.3969C45.0994 29.6371 43.4043 31.4882 41.2586 32.658C40.7737 32.9223 40.1663 32.7435 39.9019 32.2586C39.6376 31.7737 39.8163 31.1663 40.3013 30.902C42.0569 29.9449 43.4438 28.4304 44.243 26.5975C45.0423 24.7646 45.2084 22.7178 44.7152 20.78C44.222 18.8422 43.0975 17.1239 41.5193 15.8962C39.941 14.6685 37.9988 14.0013 35.9993 14H33.4799C33.024 14 32.6259 13.6916 32.5118 13.2503C31.9442 11.0551 30.8864 9.01715 29.4179 7.28964C27.9494 5.56212 26.1083 4.18999 24.0332 3.27641C21.958 2.36283 19.7028 1.93157 17.437 2.01505C15.1712 2.09854 12.9538 2.69459 10.9515 3.7584C8.94917 4.82221 7.21409 6.32609 5.87667 8.15699C4.53925 9.98789 3.6343 12.0982 3.22984 14.3291C2.82538 16.5601 2.93195 18.8538 3.54153 21.0376C4.15111 23.2215 5.24783 25.2388 6.74926 26.9378C7.11497 27.3516 7.07596 27.9836 6.66211 28.3493C6.24827 28.715 5.61631 28.676 5.25059 28.2622C3.54898 26.3366 2.30602 24.0504 1.61517 21.5753C0.924311 19.1003 0.803535 16.5008 1.26192 13.9724C1.7203 11.4439 2.74592 9.05229 4.26166 6.97727C5.7774 4.90226 7.74382 3.19785 10.0131 1.9922C12.2824 0.786549 14.7954 0.111023 17.3633 0.0164108ZM23.2928 18.2929C23.6833 17.9023 24.3165 17.9023 24.707 18.2929L32.707 26.2929C33.0976 26.6834 33.0976 27.3165 32.707 27.7071C32.3165 28.0976 31.6833 28.0976 31.2928 27.7071L24.9999 21.4142V37C24.9999 37.5523 24.5522 38 23.9999 38C23.4476 38 22.9999 37.5523 22.9999 37V21.4142L16.707 27.7071C16.3165 28.0976 15.6833 28.0976 15.2928 27.7071C14.9023 27.3165 14.9023 26.6834 15.2928 26.2929L23.2928 18.2929Z"
        fill="#ACB0C0"/>
    </svg>
  )
}
