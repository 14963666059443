import React from 'react'
import {InsuranceCardPhotoButtons} from '../buttons/InsuranceCardPhotoButtons'
import classes from './styles.module.scss'

interface InsuranceCardPhotoPlaceholderProps {
    photoUrl?: string
}

export const InsuranceCardPhotoPlaceholder: React.FC<InsuranceCardPhotoPlaceholderProps> = ({photoUrl}) => {

  const getPhotoPlaceholder = () => {
    if (photoUrl.includes('.pdf')) {
      return <iframe className={classes.image} src={photoUrl}/>
    } else {
      return <img className={classes.image} src={photoUrl} alt={''}/>
    }
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.placeholder}>
        {photoUrl && getPhotoPlaceholder()}
      </div>
      <InsuranceCardPhotoButtons
        photoUrl={photoUrl}
      />
    </div>
  )
}
