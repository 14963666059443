import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

import Analytics from './components/Analytics'
import Funnel from './components/Funnel'
import { HOME_PATH } from '../../configs'
import { connect } from 'react-redux'
import { setBrowserTabTitle } from '../../helpers/setBrowserTabTitle'

const AnalyticsContainer = ({ activeBot }) => {
  const pathRoute = `${HOME_PATH}/bot/:botId/analytics`

  useEffect(() => {
    setBrowserTabTitle(`${activeBot?.name} - Analytics`)
  }, [activeBot])

  return (
    <Switch>
      <Route exact path={pathRoute}>
        <Analytics />
      </Route>
      <Route exact path={`${pathRoute}/:funnelId`}>
        <Funnel />
      </Route>
    </Switch>
  )
}

const mapStateToProps = (state: { activeBot }) => ({
  activeBot: state.activeBot,
})

export default connect(mapStateToProps)(AnalyticsContainer)
