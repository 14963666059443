import React from 'react'

export const TextTogglerIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4434_40236)">
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M8 4C8 3.44772 8.44772 3 9 3C10.0609 3 11.0783 3.42143 11.8284 4.17157C11.8877 4.23083 11.9449 4.29176 12 4.35424C12.0551 4.29176 12.1123 4.23083 12.1716 4.17157C12.9217 3.42143 13.9391 3 15 3C15.5523 3 16 3.44772 16 4C16 4.55228 15.5523 5 15 5C14.4696 5 13.9609 5.21071 13.5858 5.58579C13.2107 5.96086 13 6.46957 13 7V11H14C14.5523 11 15 11.4477 15 12C15 12.5523 14.5523 13 14 13H13V17C13 17.5304 13.2107 18.0391 13.5858 18.4142C13.9609 18.7893 14.4696 19 15 19C15.5523 19 16 19.4477 16 20C16 20.5523 15.5523 21 15 21C13.9391 21 12.9217 20.5786 12.1716 19.8284C12.1123 19.7692 12.0551 19.7082 12 19.6458C11.9449 19.7082 11.8877 19.7692 11.8284 19.8284C11.0783 20.5786 10.0609 21 9 21C8.44772 21 8 20.5523 8 20C8 19.4477 8.44772 19 9 19C9.53043 19 10.0391 18.7893 10.4142 18.4142C10.7893 18.0391 11 17.5304 11 17V13H10C9.44772 13 9 12.5523 9 12C9 11.4477 9.44772 11 10 11H11V7C11 6.46957 10.7893 5.96086 10.4142 5.58579C10.0391 5.21071 9.53043 5 9 5C8.44772 5 8 4.55228 8 4Z" fill="#5A98F7"/>
    </g>
    <defs>
      <clipPath id="clip0_4434_40236">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)
