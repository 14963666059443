import React, { FC, useState } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment-timezone'
import { Timeit } from 'react-timeit'
import { rescheduleResultDelivery } from '../../api/contactInfoApi'
import { ArrowDownIcon } from '../common/icons/ArrowDownIcon'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import classes from './styles.module.scss'

interface Props {
  match: any
  onClose: () => void
}

export const ReScheduleResultsModal: FC<Props> = ({ match, onClose }) => {
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(false)
  const [reScheduleResultsDelivery, setReScheduleResultsDelivery] = useState<moment.Moment>(moment.tz('America/New_York'))
  const [reScheduleResultsDeliverySelected, setReScheduleResultsDeliverySelected] = useState<boolean>(false)
  const [isReScheduleResultsDeliveryValid, setIsReScheduleResultsDeliveryValid] = useState<boolean>(true)
  const [reScheduleResultDeliveryPickerOpen, setReScheduleResultDeliveryPickerOpen] = useState<boolean>(false)
  const [isSubmitButtonWasClicked, setIsSubmitButtonWasClicked] = useState<boolean>(false)
  const [timePickerOpen, setTimePickerOpen] = useState<boolean>(false)
  const [isTimeValid, setIsTimeValid] = useState<boolean>(true)
  const [timeSelected, setTimeSelected] = useState<boolean>(false)
  const [time, setTime] = useState<string>(null)

  const parseDate = (timestamp) => {
    const date = new Date(timestamp)
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
  }

  const validateFields = (
    reScheduleResultsDeliverySelected: boolean,
    timeSelected: boolean,
    isSubmitButtonWasClicked: boolean
  ) => {
    if (isSubmitButtonWasClicked) {
      setIsReScheduleResultsDeliveryValid(reScheduleResultsDeliverySelected)
      setIsTimeValid(timeSelected)
    }

    setDisableSubmitButton(
      (!reScheduleResultsDeliverySelected || !timeSelected) && isSubmitButtonWasClicked
    )

    return reScheduleResultsDeliverySelected && timeSelected
  }

  const handleSubmitButtonClick = () => {
    setIsSubmitButtonWasClicked(true)
    const isAllFieldsValid = validateFields(
      reScheduleResultsDeliverySelected,
      timeSelected,
      true
    )

    if (isAllFieldsValid) {
      rescheduleResultDelivery({
        resultDeliveryDate: reScheduleResultsDelivery,
        resultDeliveryTime: time
      }, match.params.botId, match.params.contactId)
      onClose()
    }
  }

  const setScheduleResultsDeliveryDate = (event, picker) => {
    setReScheduleResultsDelivery(moment.tz(picker.startDate, 'America/New_York'))
    setReScheduleResultsDeliverySelected(true)
    setIsReScheduleResultsDeliveryValid(true)

    validateFields(
      true,
      timeSelected,
      isSubmitButtonWasClicked
    )
  }

  const setTimeDate = (time: string) => {
    setTime(time)
    setTimeSelected(true)
    setIsTimeValid(true)
    setTimePickerOpen(false)

    validateFields(
      reScheduleResultsDeliverySelected,
      true,
      isSubmitButtonWasClicked
    )
  }

  return (
    <div className={classes.modalWrapper}>
      <h2 className={classes.header}>
        Schedule results delivery
      </h2>
      <div className={classes.dateSelectionWrapper}>
        <span className={classes.dateTitle}>Schedule results delivery</span>
        <DateRangePicker
          singleDatePicker
          minDate={moment.tz('America/New_York')}
          className={classes.dateRangePickerContainer}
          startDate={reScheduleResultsDelivery}
          endDate={reScheduleResultsDelivery}
          onApply={setScheduleResultsDeliveryDate}>
          <div
            className={classes.chooseDate}
            onClick={() => setReScheduleResultDeliveryPickerOpen(!reScheduleResultDeliveryPickerOpen)}
          >
            <span
              className={isReScheduleResultsDeliveryValid ?
                classes.chooseDatePlaceholder :
                classes.chooseDatePlaceholderError
              }
            >
              {reScheduleResultsDeliverySelected ? parseDate(reScheduleResultsDelivery) : 'Choose date'}
            </span>
            <div className={classes.iconWrapper}>
              <ArrowDownIcon/>
            </div>
          </div>
        </DateRangePicker>
      </div>

      <div className={classes.dateSelectionWrapper}>
        <span className={classes.dateTitle}>Time</span>
        <div
          className={classes.chooseTimeWrapper}
          onClick={() => setTimePickerOpen(!timePickerOpen)}
        >
          <span className={isTimeValid ? classes.chooseDatePlaceholder : classes.chooseDatePlaceholderError}>
            {timeSelected ? time : 'Choose time'}
          </span>
          <div className={classes.iconWrapper}>
            <ArrowDownIcon/>
          </div>
        </div>
        {timePickerOpen && (
          <div className={classes.reScheduleTimeSelectorWrapper}>
            <span style={{marginLeft: '25px', marginRight: '25px'}}
              className={classes.dateTitle}>Please note that the scheduled delivery time
  should be at least 3 minutes ahead of your real-time</span>
            <Timeit defualtValue={time ? time : '00:00'} onChange={e => setTime(e)}/>
            <div
              onClick={() => setTimeDate(time)}
              style={{color: '#1658F3', paddingTop: '24px', paddingBottom: '12px', cursor: 'pointer'}}
            >
              Done
            </div>
          </div>
        )}
      </div>

      <SubmitButton
        disabled={disableSubmitButton}
        styles={{
          width: '97px',
          height: '48px',
          margin: '30px auto 0',
          display: 'block'
        }}
        onClick={handleSubmitButtonClick}
        title={'Schedule'}
      />
    </div>
  )
}
