/* eslint-disable */
import React from 'react'

export const Clip = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.2" clip-path="url(#clip0_1948_11902)">
        <path
          d="M61.1941 12.1527L57.8099 8.76903C54.0716 5.03011 48.0101 5.03011 44.2711 8.76903L32.3684 20.6694C28.5565 17.7105 23.1383 18.0518 19.7272 21.4652L2.80419 38.3794C-0.934729 42.1184 -0.934729 48.1799 2.80419 51.9188L6.18843 55.3024C9.92676 59.0414 15.9883 59.0414 19.7272 55.3024L31.624 43.4056C35.436 46.3645 40.8542 46.0244 44.2653 42.6109L61.1941 25.6955C64.9354 21.9566 64.9354 15.8922 61.1941 12.1527V12.1527ZM47.6554 12.1527C49.5246 10.2835 52.5553 10.2835 54.4251 12.1527L57.8099 15.5369C59.6796 17.4061 59.6796 20.4374 57.8099 22.3066L40.8811 39.2296C39.3397 40.7745 36.9464 41.0783 35.0679 39.9664L36.6502 38.3794C40.3879 34.6411 40.3879 28.5808 36.6502 24.8419L35.8041 23.9946L47.6554 12.1527ZM30.7266 29.0757L32.4199 27.3836L33.2659 28.2296C35.1351 30.0994 35.1351 33.1301 33.2659 34.9993L31.5726 36.6914L30.7266 35.8454C28.8574 33.9762 28.8574 30.9454 30.7266 29.0757ZM16.3429 51.9223C14.4738 53.7914 11.443 53.7914 9.57385 51.9223L6.18843 48.538C4.31868 46.6689 4.31868 43.6381 6.18843 41.7684L23.1114 24.846C24.6528 23.3005 27.0461 22.9972 28.9246 24.1086L27.3423 25.6955C23.6046 29.4339 23.6046 35.4948 27.3423 39.2331L28.1884 40.0803L16.3429 51.9223Z"
          fill="#B0B2C0"
        />
      </g>
      <defs>
        <clipPath id="clip0_1948_11902">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
