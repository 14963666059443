import React, { FC } from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import Modal from '../../../../uiKit/Modal'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import {closeUserAccessModal} from '../../actions/contactsModalsAction'
import classes from '../../styles.module.scss'
import {updateUserAccess} from '../../api/contactsApi'

interface Props {
  userAccessModalState: {
    isUserAccessModalOpen: boolean
    isActive?: boolean
    contactId?: number
  },
  contactsPage: number,
  match: any
}

const UserAccessModal: FC<Props> = ({ userAccessModalState, contactsPage, match }) => {
  const { isUserAccessModalOpen, isActive, contactId } = userAccessModalState

  const onClickHandler = () => {
    updateUserAccess(match.params.botId, contactId, !isActive, contactsPage || 0)
  }

  return (
    <Modal
      classes={{paper: classes.accessModal}}
      open={isUserAccessModalOpen}
      onClose={closeUserAccessModal}
    >
      <div className={classes.accessText}>
        Are you sure you want to {isActive ? 'withdraw' : 'enable'} access for this user?
      </div>
      <div className={classes.accessButtonContainer}>
        <SubmitButton
          disabled={false}
          empty={true}
          styles={{
            width: '154px',
            height: '48px',
            marginRight: '8px',
            border: 'none'
          }}
          onClick={closeUserAccessModal}
          title={'Back'}
        />
        <SubmitButton
          disabled={false}
          styles={{
            width: '154px',
            height: '48px',
          }}
          onClick={onClickHandler}
          title={`${isActive ? 'Withdraw' : 'Enable'} access`}
        />
      </div>
    </Modal>
  )
}

const mapStateToProps = state => ({
  contactsSize: state.contactsSize,
  contactsPage: state.contactsPage,
  userAccessModalState: state.userAccessModalState,
})

export default withRouter(connect(mapStateToProps)(UserAccessModal))
