export const styles = () => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  textFieldsWrap: {
    display: 'flex',
    overflow: 'scroll',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 32,
    '&:after': {
      display: 'table',
      content: '',
      clear: 'both'
    },
    '&::-webkit-scrollbar': {
      height: 6,
    },
    '&::-webkit-scrollbar-thumb': {
      opacity: 0.6,
      backgroundColor: 'rgba(99, 99, 99, 0.2)',
    },
  },
  message: {
    width: 280,
    position: 'relative',
    padding: 0,
    borderRadius: 10,
    backgroundColor: '#f8f8f8',
    textAlign: 'left',
    minWidth: 250,
    margin: '0px 0px 10px',
    maxWidth: 250,
    '&:hover': {
      '& i': {
        display: 'flex',
      },
    },
  },
  deleteTemplate: {
    left: '228px',
    top: '0px',
    position: 'absolute',
    display: 'none',
    transition: 'all 0.5s ease',
  },
  deleteIcon: {
    background: 'white',
    border: '1px solid #EBEBEF',
    boxShadow: '0px 3px 6px #EBEBEF',
    width: 40,
    height: 40,
    position: 'absolute',
    left: 40,
    display: 'block',
  },
  reactionIcon: {
    background: 'white',
    border: '1px solid #EBEBEF',
    boxShadow: '0px 3px 6px #EBEBEF',
    width: 40,
    height: 40,
    position: 'absolute',
    left: 0,
    display: 'block',
  },
  tooltip: {
    margin: 10,
    color: '#f44336',
  },
  keyInput: {
    width: '100%',
    border: '1px solid #e5e5e5',
    borderBottom: '0px',
    borderRadius: '10px 10px 0px 0px',
    outline: 'none',
    height: 40,
    fontSize: 14,
    padding: '0px 10px',
  },
  buttonsContainer: {
    backgroundColor: 'white',
    borderRadius: '0 0 10px 10px',
    border: '1px solid #e5e5e5',
    borderTop: '0px solid #e5e5e5',
  },
  textWrap: {
    minWidth: 250,
    marginLeft: 10,
    position: 'relative',
    '&:hover': {
      '& i': {
        display: 'block',
      },
    },
  },
  deleteText: {
    background: 'white',
    border: '1px solid #EBEBEF',
    boxShadow: '0px 3px 6px #EBEBEF',
    width: 40,
    height: 40,
  },
  plusIconWrap: {
    minWidth: 250,
    height: 120,
    marginLeft: 10,
    border: '1px solid #e5e5e5',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  deleteIconWrap: {
    position: 'absolute',
    right: -19,
    display: 'none',
    marginTop: -20,
    zIndex: 100,
  },
  addButton: {
    background: '#5A98F7',
    color: 'white',
    fontWeight: 500,
    padding: 6,
    textAlign: 'center',
    cursor: 'pointer',
    margin: 'auto',
    borderRadius: '0 0 10px 10px',
    '&:hover': {
      color: '#0084ff',
      background: 'white',
    },
  },
})
