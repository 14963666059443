import React from 'react'
import {CustomButton} from '../../common/CustomButton'
import classes from './styles.module.scss'
import {ContactInfo, GtKitInfo, GtKitOption} from '../../../api/model/ContactInfo'
import {connect} from 'react-redux'
import {updateGtKitInfo} from '../../../api/contactInfoApi'

interface GTKitButtonsBlockProps {
    match: any
    contactInfo: ContactInfo
    gtKitInfo: GtKitInfo
}

const GTKitButtonsBlock: React.FC<GTKitButtonsBlockProps> = ({match, contactInfo, gtKitInfo}) => {

  const {isRequiredStatus} = contactInfo
  const {gtKitOption} = gtKitInfo

  const handleConfirmSending = e => {
    updateGtKitInfo(match.params.botId, match.params.contactId, GtKitOption.CONFIRMED)
  }

  const handleRejectSending = e => {
    updateGtKitInfo(match.params.botId, match.params.contactId, GtKitOption.REJECTED)
  }

  return (
    <div className={classes.buttonsWrapper}>
      <div className={classes.buttonWrapper}>
        <CustomButton
          onBtnClick={handleConfirmSending}
          fulled={gtKitOption === GtKitOption.CONFIRMED}
          disabled={!isRequiredStatus}
          text={'Confirm sending GT kit'}/>
        {gtKitOption === GtKitOption.CONFIRMED && <div className={classes.kitWasSent}>GT kit was sent</div>}
      </div>
      <div className={classes.buttonWrapper}>
        <CustomButton
          onBtnClick={handleRejectSending}
          fulled={gtKitOption === GtKitOption.REJECTED}
          disabled={gtKitOption === GtKitOption.CONFIRMED || !isRequiredStatus}
          text={'Reject sending GT kit'}/>
        {gtKitOption === GtKitOption.REJECTED &&
            <div className={classes.notAbleToSendGtKit}>Not able to send GT kit</div>}
      </div>
    </div>
  )
}

const mapStateToProps = (state: { contactInfo: ContactInfo, gtKitInfo: GtKitInfo }) => ({
  contactInfo: state.contactInfo,
  gtKitInfo: state.gtKitInfo
})

export default connect(mapStateToProps)(GTKitButtonsBlock)
