import React from 'react'
import classes from './styles.module.scss'
import {CustomButton} from '../../common/CustomButton'
import {ContactInfo, ResultsDeliveryInfo, ResultStatus} from '../../../api/model/ContactInfo'
import {connect} from 'react-redux'
import {confirmResultsDelivery} from '../../../api/contactInfoApi'

interface ConfirmResultsSectionProps {
  match: any
  contactInfo: ContactInfo
  resultsDeliveryInfo: ResultsDeliveryInfo
}

const ConfirmResultsSection: React.FC<ConfirmResultsSectionProps> = ({match, contactInfo, resultsDeliveryInfo}) => {
  const {isRequiredStatus} = contactInfo
  const {
    resultStatus,
    isResultsDeliveryConfirmed,
    resultsDeliveryConfirmationTimestamp
  } = resultsDeliveryInfo

  const handleClick = e => {
    confirmResultsDelivery(match.params.botId, match.params.contactId)
  }
  return (
    <div className={classes.wrapper}>
      <span className={classes.text}>
         Only for positive results
      </span>
      <div className={classes.wrapperBottom}>
        <CustomButton
            text={isResultsDeliveryConfirmed ? 'Results delivered' : 'Confirm results delivery'}
            disabled={resultStatus !== ResultStatus.POSITIVE || !isRequiredStatus}
            fulled={resultStatus === ResultStatus.POSITIVE && !!resultsDeliveryConfirmationTimestamp}
            onBtnClick={handleClick}
        />
        {isResultsDeliveryConfirmed && resultStatus === ResultStatus.POSITIVE &&
            <span className={classes.dateTime}>{formatDate(resultsDeliveryConfirmationTimestamp)}</span>}
      </div>
    </div>
  )
}

const mapStateToProps = (state: { contactInfo: ContactInfo, resultsDeliveryInfo: ResultsDeliveryInfo }) => ({
  contactInfo: state.contactInfo,
  resultsDeliveryInfo: state.resultsDeliveryInfo,
})

export default connect(mapStateToProps)(ConfirmResultsSection)
export const formatDate = (strDateTime: string) => {
  const date = new Date(strDateTime)
  const months = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ]

  const hours = date.getHours()
  const minutes = date.getMinutes()
  const amOrPm = hours >= 12 ? 'pm' : 'am'
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
  const month = months[date.getMonth()]
  const day = date.getDate()
  const year = date.getFullYear()

  return `${formattedHours}:${formattedMinutes} ${amOrPm}, ${month} ${day}, ${year}`
}
