import { OXFORD_BLUE, PERMISSION_RED } from 'constants/colors'

export const styles = () => ({
  nameBlock: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    '& > img': {
      display: 'block',
      width: 40,
      height: 40,
      borderRadius: '50%',
      marginRight: 12,
    },
  },
  item: {
    marginTop: 22,
    '&:first-child': {
      margin: 0,
    },
  },
  itemLabel: {
    marginBottom: 2,
    color: OXFORD_BLUE,
    opacity: 0.7,
    fontSize: 14
  },
  itemField: {
    color: 'rgba(53, 64, 82, 0.7)',
    margin: 0,
  },
  tooltip: {
    maxWidth: 300,
    left: '24px !important',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    opacity: '1 !important',
    '&:after': {
      marginLeft: '-68px !important',
    },
  },
  textarea: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '8px 12px',
    border: '1px solid rgba(53, 64, 82, 0.1)',
    borderRadius: '4px',
    marginBottom: '20px',
    height: 116,
    fontSize: 14,
    outline: 'none',
    position: 'relative',
    resize: 'vertical',
    display: 'inline-block',
    zIndex: 0,
    '&::placeholder': {
      color: 'rgba(53, 64, 82, 0.7)',
    },
  },
  styleError: {
    position: 'absolute',
    color: PERMISSION_RED,
    bottom: 80,
    fontSize: 12
  },
})
