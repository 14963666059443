import React from 'react'
import { Handle, Position } from 'reactflow'
import { TechnicalNodeContainer } from './CustomNode.styles'

const TechnicalNode = () => {

  return (
    <TechnicalNodeContainer>
      <Handle type='target' position={Position.Top} id='target-top' />
      <Handle type='target' position={Position.Bottom} id='target-bottom' />
      <Handle type='target' position={Position.Left} id='target-left' />
      <Handle type='target' position={Position.Right} id='target-right' />

      <Handle type='source' position={Position.Top} id='source-top' />
      <Handle type='source' position={Position.Bottom} id='source-bottom' />
      <Handle type='source' position={Position.Left} id='source-left' />
      <Handle type='source' position={Position.Right} id='source-right' />
    </TechnicalNodeContainer>
  )
}

export default TechnicalNode
