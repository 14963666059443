import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {InfoField} from '../../common/InfoField'
import classes from './styles.module.scss'
import {ContactInfo} from '../../../api/model/ContactInfo'
import {locationData} from './locationData'

export const preparePhoneNumber = (phone: string): string => {
  return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`
}

interface GeneralInfoSectionProps {
    contactInfo: ContactInfo
}

const GeneralInfoSection: React.FC<GeneralInfoSectionProps> = ({contactInfo}) => {

  const {
    firstAndLastName,
    username,
    email,
    phone,
    recruitmentLocation,
    activationLink
  } = contactInfo
  return (
    <>
      <div className={classes.userName}>
        {firstAndLastName}
      </div>
      <InfoField
        title={'User name'}
        value={username}
      />
      <InfoField
        title={'Email address'}
        value={email}
      />
      <InfoField
        title={'Phone number'}
        value={phone ? preparePhoneNumber(phone) : 'NONE'}
      />
      <InfoField
        title={'Recruitment location'}
        value={locationData[recruitmentLocation]}
        styles={{ marginTop: 20 }}
      />
      <InfoField
        title={'Activation link'}
        value={activationLink ? activationLink : 'NONE'}
        styles={{ marginTop: 20 }}
        link={!!activationLink }
      />
    </>
  )
}

const mapStateToProps = state => ({
  contactInfo: state.contactInfo
})

export default withRouter(connect(mapStateToProps)(GeneralInfoSection))
