import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form/dist/index.ie11'

import { getFacebookPages } from '../../../../api/facebook'

import { NewPageGeneral } from '../NewPageGeneral'

import { useFacebookActivePagesContext } from '../FacebookActivePages/context'

import { buttonStyles } from './styles'
import SubmitButton from 'uiKit/buttons/SubmitButton'
import { alertSuccess, alertError } from 'api'
import LoaderSmall from 'uiKit/loaders/loaderSmall'

interface Props {
  classes: any
  botId: number
  pages: any
  fbToken: string
  closeNewPageModalHandle: () => void
}

export const NewPage: React.FC<Props> = props => {
  const { classes } = props
  const { control, watch, handleSubmit, setValue, getValues } = useForm({
    shouldUnregister: false,
  })

  const { setNewPage, isLoading, getPages } = useFacebookActivePagesContext()
  let pageNameValue = getValues('pageName')
  const isDisabledSubmitButton = isLoading || !pageNameValue

  useEffect(() => {
    watch(['pageName'], pageName => {
      pageNameValue = pageName
    })
  }, [watch])

  const onSubmit = data => {
    setNewPage(props.botId, data, props.pages)
      .then(() => {
        alertSuccess('Page connected successfully')
        getFacebookPages(props.botId, props.fbToken).then(() => getPages(props.botId))
      })
      .catch(error => alertError(error.message || 'Page subscribe error'))
      .finally(() => props.closeNewPageModalHandle())
  }

  const handleCancel = e => {
    e.preventDefault()
    props.closeNewPageModalHandle()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      {isLoading ? (
        <div className={classes.loaderScreen}>
          <LoaderSmall showLoader={true} />
        </div>
      ) : (
        <NewPageGeneral control={control} setValue={setValue} pages={props.pages} />
      )}
      <div className={classes.buttons}>
        <SubmitButton empty title="Cancel" onClick={handleCancel} styles={buttonStyles} />
        <SubmitButton title="Save" disabled={isDisabledSubmitButton} styles={{ ...buttonStyles, marginLeft: 0 }} />
      </div>
    </form>
  )
}
