import React, { FC, useRef } from 'react'
import classes from '../styles.module.scss'
import { UploadFileIcon } from '../../common/icons/UploadFileIcon'
import { PdfFileIcon } from '../../common/icons/PdfFileIcon'
import { DeleteFileIcon } from '../../common/icons/DeleteFileIcon'

interface Props {
  title: string
  subTitle: string
  isFileValid: boolean
  onChange: (e: any) => void
  handleDeleteFile: () => void
  uploadProgress: number
  selectedUserFile: any
}

const FileDropZone: FC<Props> = ({
  title,
  subTitle,
  isFileValid,
  onChange,
  handleDeleteFile,
  uploadProgress,
  selectedUserFile
}) => {
  const fileInputRef = useRef<any>(null)

  const prepareFileSize = (fileSize) => {
    if (fileSize / 1024 > 1000) {
      return `${(fileSize / 1024 / 1024).toFixed(1)}MB`
    }
    return `${(fileSize / 1024).toFixed()}KB`
  }

  return (
    <>
      {!selectedUserFile && <div className={classes.selectFileWrapper} onClick={() => fileInputRef.current.click()}>
        <div className={classes.uploadFileImageWrapper}>
          <UploadFileIcon/>
        </div>
        <div className={classes.uploadFileRightSection}>
          <div className={classes.uploadFileTextWrapper}>
            <span className={classes.uploadFileTextBold}>
              {title}
            </span>
            <span className={classes.uploadFileTextLight}>
              {subTitle}
            </span>
            <span className={classes.uploadFileTextLight}>
              File size should be no more than 10MB
            </span>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{display: 'none'}}
            accept=".pdf"
            onChange={onChange}
          />
          <div className={isFileValid ? classes.selectFileBtn : classes.selectFileBtnError}>
            Select file
          </div>
        </div>
      </div>}

      {uploadProgress !== 0 && (
        <div className={classes.uploadedFileWrapper}>
          <span className={classes.uploadedFileTitleText}>
            {uploadProgress === 100 ? 'File uploaded' : 'File uploading'}
          </span>
          <div className={classes.fileUploadingProgressWrapper}>
            <PdfFileIcon/>
            <div className={classes.fileUploadingProgressRightSection}>
              <span className={classes.fileUploadingProgressText}>{selectedUserFile?.name}</span>
              {uploadProgress !== 100 && (
                <div
                  style={{
                    width: `${uploadProgress}%`,
                    height: '2px',
                    backgroundColor: '#5A98F7',
                    borderRadius: '4px',
                  }}/>
              )}
            </div>
            {uploadProgress === 100 && (
              <div className={classes.fileInfoWrapper}>
                <span className={classes.fileSize}>{prepareFileSize(selectedUserFile?.size)}</span>
                <div
                  onClick={handleDeleteFile}
                  style={{cursor: 'pointer'}}
                >
                  <DeleteFileIcon/>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default FileDropZone
