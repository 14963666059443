import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import Select, { components } from 'react-select'
import Modal from '../../../../uiKit/Modal'
import TagModal from './TagModal'

const styles = theme => ({
  createNewTagLink: {
    marginTop: 10,
    fontSize: 12,
    cursor: 'pointer',
    fontFamily: 'Lato',
    textDecoration: 'underline',
    width: 'max-content',
  },
})

class Tags extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openTagModal: false,
    }
  }

  handleChangeTags = event => {
    this.props.onChange(event)
  }

  handleDelete = data => () => {
    this.setState(
      state => {
        const tagsSelected = [...state.tagsSelected]
        const chipToDelete = tagsSelected.indexOf(data)
        tagsSelected.splice(chipToDelete, 1)
        return { tagsSelected }
      },
      () => this.props.payload(this.state.tagsSelected),
    )
  }

  openCreateTagModal = () => {
    this.setState({ openTagModal: true })
  }

  closeCreateTagModal = () => {
    this.setState({ openTagModal: false })
  }

  convertValuesToOptions(values) {
    return values.map(tag => {
      return {
        value: tag.value,
        label: tag.value,
        id: tag.id,
      }
    })
  }

  Input = props => {
    return (
      <components.Input {...props} data-autotest='tag-selector'/>
    )
  }

  render() {
    const { classes, style } = this.props

    return (
      <div style={style}>
        <Select
          isMulti
          components={{Input: this.Input}}
          value={this.convertValuesToOptions(this.props.value)}
          onChange={this.handleChangeTags}
          options={this.convertValuesToOptions(this.props.tags).sort(
            (tag1, tag2) => tag2.id - tag1.id,
          )}
        />
        {!this.props.disableNew && (
          <p
            className={classes.createNewTagLink}
            onClick={this.openCreateTagModal}>
            Create new tag
          </p>
        )}
        <Modal
          open={this.state.openTagModal}
          onClose={this.closeCreateTagModal}
          title="Create a tag">
          <TagModal
            closeTagModal={this.closeCreateTagModal}
            allTags={this.props.tags}
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  tags: state.tags,
})

export default withStyles(styles)(connect(mapStateToProps)(Tags))
