import SockJS from 'sockjs-client'
import { Stomp } from '@stomp/stompjs'
import { BACKEND_URL } from '../configs'
import { ERR_SECURITY_ACCESS_DENIED } from '../constants/websocketErrors'
import isJsonString from '../helpers/isJsonString'

class WebSocketClient {
  _client

  _messageSubscription
  _chatStatusSubscription
  _unassignedCountSubscription
  _unreadAssignedToCountSubscription

  _receiveMessageCallback
  _receiveChatStatusCallback
  _receiveUnassignedCountCallback
  _receiveUnreadAssignedToCountCallback

  connect(botId, adminId) {
    this._createNewSocketConnection()
    this._client.connect(
      {},
      () => this._onConnectionSuccess(botId, adminId),
      error => this._onConnectionError(error),
    )
  }

  _createNewSocketConnection() {
    this._client = Stomp.over(this._createSockJs)
    this._client.reconnect_delay = 5000
    this._client.heartbeat.incoming = 10000
    this._client.heartbeat.outgoing = 10000
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._client.debug = () => {} //NOSONAR
  }

  _createSockJs = () => {
    //TODO: how to handle auth in websockets?
    return new SockJS(`${BACKEND_URL}/web`)
  }

  _onConnectionSuccess(botId, adminId) {
    if (this._client.connected) {
      this._subscribeForTopics(botId, adminId)
    }
  }

  _onConnectionError(error) {
    if (isJsonString(error?.body) && JSON.parse(error?.body)?.error?.code === ERR_SECURITY_ACCESS_DENIED) {
      this._client.disconnect()
    }
  }

  _subscribeForTopics(botId, adminId) {
    this._messageSubscription = this._client.subscribe(`/topic/admin.message.${botId}`, message =>
      this._receiveMessageCallback(message),
    )

    this._chatStatusSubscription = this._client.subscribe(`/topic/admin.chatStatus.${botId}`, chatStatus =>
      this._receiveChatStatusCallback(chatStatus),
    )

    this._unassignedCountSubscription = this._client.subscribe(`/topic/admin.unassignedCount.${botId}`, requestCount =>
      this._receiveUnassignedCountCallback(requestCount),
    )

    this._unreadAssignedToCountSubscription = this._client.subscribe(
      `/topic/admin.unreadAssignedToCount.${botId}.${adminId}`,
      requestCount => this._receiveUnreadAssignedToCountCallback(requestCount),
    )
  }

  configureMessageTopic(receiveMessageCallback) {
    this._receiveMessageCallback = receiveMessageCallback
  }

  configureChatStatusTopic(receiveChatStatusCallback) {
    this._receiveChatStatusCallback = receiveChatStatusCallback
  }

  configureUnassignedCountTopic(receiveUnassignedCountCallback) {
    this._receiveUnassignedCountCallback = receiveUnassignedCountCallback
  }

  configureUnreadAssignedToCountTopic(receiveUnreadAssignedToCountCallback) {
    this._receiveUnreadAssignedToCountCallback = receiveUnreadAssignedToCountCallback
  }

  sendMessage(message) {
    if (this._client?.connected) {
      this._client.send('/app/admin', {}, JSON.stringify(message))
    }
  }

  sendStatus(status, body, botId) {
    if (body.nativeMessage) {
      const nativeMessage = JSON.parse(body.nativeMessage)

      if (nativeMessage.message) {
        const statusObj = {
          status: {
            messageId: nativeMessage.message.id,
            status,
          },
          chatId: nativeMessage.chatId,
          websiteLocation: nativeMessage.websiteLocation,
        }

        if (!nativeMessage?.isFromBot && nativeMessage?.chatId && this._client?.connected) {
          this._client.send(`/app/widget/${botId}/${nativeMessage.chatId}`, {}, JSON.stringify(statusObj))
        }
      }
    }
  }

  disconnect() {
    if (this._client?.connected) {
      this._messageSubscription.unsubscribe()
      this._chatStatusSubscription.unsubscribe()
      this._unassignedCountSubscription.unsubscribe()
      this._unreadAssignedToCountSubscription.unsubscribe()
      this._client.disconnect()
    }
  }
}

export default WebSocketClient
