/* eslint-disable max-len */
import React, { ReactElement } from 'react'

export const SparklesIcon = (): ReactElement => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 18C9 16.4087 9.63214 14.8826 10.7574 13.7574C11.8826 12.6321 13.4087 12 15 12C13.4087 12 11.8826 11.3679 10.7574 10.2426C9.63214 9.11742 9 7.5913 9 6C9 7.5913 8.36786 9.11742 7.24264 10.2426C6.11742 11.3679 4.5913 12 3 12C4.5913 12 6.11742 12.6321 7.24264 13.7574C8.36786 14.8826 9 16.4087 9 18Z"
        fill="url(#paint0_linear_5583_6250)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3C18.5523 3 19 3.44772 19 4C19 4.26522 19.1054 4.51957 19.2929 4.70711C19.4804 4.89464 19.7348 5 20 5C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7C19.7348 7 19.4804 7.10536 19.2929 7.29289C19.1054 7.48043 19 7.73478 19 8C19 8.55228 18.5523 9 18 9C17.4477 9 17 8.55228 17 8C17 7.73478 16.8946 7.48043 16.7071 7.29289C16.5196 7.10536 16.2652 7 16 7C15.4477 7 15 6.55228 15 6C15 5.44772 15.4477 5 16 5C16.2652 5 16.5196 4.89464 16.7071 4.70711C16.8946 4.51957 17 4.26522 17 4C17 3.44772 17.4477 3 18 3ZM9 5C9.55228 5 10 5.44772 10 6C10 7.32608 10.5268 8.59785 11.4645 9.53553C12.4021 10.4732 13.6739 11 15 11C15.5523 11 16 11.4477 16 12C16 12.5523 15.5523 13 15 13C13.6739 13 12.4021 13.5268 11.4645 14.4645C10.5268 15.4021 10 16.6739 10 18C10 18.5523 9.55228 19 9 19C8.44772 19 8 18.5523 8 18C8 16.6739 7.47322 15.4021 6.53553 14.4645C5.59785 13.5268 4.32608 13 3 13C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11C4.32608 11 5.59785 10.4732 6.53553 9.53553C7.47322 8.59785 8 7.32608 8 6C8 5.44772 8.44772 5 9 5ZM9 9.60559C8.70843 10.0908 8.35673 10.5428 7.94975 10.9497C7.54276 11.3567 7.09082 11.7084 6.60559 12C7.09082 12.2916 7.54276 12.6433 7.94975 13.0503C8.35673 13.4572 8.70843 13.9092 9 14.3944C9.29157 13.9092 9.64327 13.4572 10.0503 13.0503C10.4572 12.6433 10.9092 12.2916 11.3944 12C10.9092 11.7084 10.4572 11.3567 10.0503 10.9497C9.64327 10.5428 9.29157 10.0908 9 9.60559ZM16.7071 16.7071C16.8946 16.5196 17 16.2652 17 16C17 15.4477 17.4477 15 18 15C18.5523 15 19 15.4477 19 16C19 16.2652 19.1054 16.5196 19.2929 16.7071C19.4804 16.8946 19.7348 17 20 17C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19C19.7348 19 19.4804 19.1054 19.2929 19.2929C19.1054 19.4804 19 19.7348 19 20C19 20.5523 18.5523 21 18 21C17.4477 21 17 20.5523 17 20C17 19.7348 16.8946 19.4804 16.7071 19.2929C16.5196 19.1054 16.2652 19 16 19C15.4477 19 15 18.5523 15 18C15 17.4477 15.4477 17 16 17C16.2652 17 16.5196 16.8946 16.7071 16.7071Z"
        fill="url(#paint1_linear_5583_6250)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5583_6250"
          x1="11.4059"
          y1="21"
          x2="4.54058"
          y2="6.18048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#5A98F7" />
          <stop offset="1" stopColor="#1658F3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5583_6250"
          x1="11.4059"
          y1="21"
          x2="4.54058"
          y2="6.18048"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#5A98F7" />
          <stop offset="1" stopColor="#1658F3" />
        </linearGradient>
      </defs>
    </svg>
  )
}
