import React from 'react'
import {NavLink, withRouter} from 'react-router-dom'
import {HOME_PATH} from '../../../../configs'
import {BackToAllContactsArrow} from '../../../../uiKit/icons/BackToAllContactsArrow'
import classes from './styles.module.scss'

interface BackToContactsProps {
    match: any
}

export const BackToContacts: React.FC<BackToContactsProps> = ({match}) => {

  return (
    <div className={classes.wrapper}>
      <div className={classes.arrowWrapper}>
        <NavLink
          to={`${HOME_PATH}/bot/${match.params.botId}/contacts`}>
          <BackToAllContactsArrow/>
        </NavLink>
      </div>
      <div className={classes.text}>
        Back to Contacts
      </div>
    </div>
  )
}

export default withRouter(BackToContacts)
