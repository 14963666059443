import { CADET_BLUE, COMET_GREY, RIBBON_BLUE, WHITE } from 'constants/colors'
import styled from 'styled-components'

const SelectorContainer = styled.div`
  max-width: 400px;
  margin-bottom: 24px;
  position: relative;
  z-index: 3;

  & > div:first-child {
    margin-bottom: 8px;
  }
`

const SubHeader = styled.div`
  font-size: 12px;
  color: ${COMET_GREY};
`

const Button = styled.button`
  background: ${RIBBON_BLUE};
  width: 128px;
  height: 48px;
  border-radius: 10px;
  border: none;
  color: ${WHITE};
  font-size: 16px;
  &:disabled {
    background: ${CADET_BLUE};
  }
`

export { SelectorContainer, SubHeader, Button }
