import styled from 'styled-components'
import { WHITE, ATHENS_GRAY_DARK, HAWKES_BLUE, MINE_SHAFT, PERIWINKLE } from 'constants/colors'

export const ItemChipHelperContainer = styled.i`
  left: 208px;
  top: 0;
  position: absolute;
  display: none;
  transition: all 0.5s ease;
  background-color: ${WHITE};
  box-shadow: 0 3px 6px ${ATHENS_GRAY_DARK};
  border-radius: 24px;
  z-index: 1;
  justify-content: center;
  align-items: center;
  
  &:hover {
    justify-content: center;
    align-items: center;
  }
`

export const ChipHelperButtonTooltip = styled.span`
  visibility: hidden;
  color: ${WHITE};
  background-color: ${MINE_SHAFT};
  padding: 8px;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  top: 44px;
  left: 50%;
  transform: translateX(-50%);
`

export const ChipHelperButtonContainer = styled.button`
  position: relative;
  border: none;
  background-color: ${props => (props.enableReaction ? PERIWINKLE : WHITE)};
  padding: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-self: center;
  
  &:hover {
    background-color: ${props => (props.enableReaction ? PERIWINKLE : HAWKES_BLUE)};
  }

  &:hover ${ChipHelperButtonTooltip} {
    visibility: visible;
    transition: visibility .1s .4s;
  }
`

export const CheckIconContainer = styled.span`
  position: absolute;
  top: -6px;
  right: -1px;
`


