import React from 'react'
import classes from './styles.module.scss'
import Tooltip from '../../../../../uiKit/TooltipInfo/Tooltip'

export const ContactsTableHeader: React.FC = ({}) => {

  return (
    <div style={{background: '#F7FAFE'}}>
      <div className={classes.contactItemHeader}>
        <div className={classes.firstAndLastName}>First name and Last name</div>
        <div className={classes.userName}>Username</div>
        <div className={classes.email}>Email address</div>
        <div className={classes.phone}>Phone number</div>
        <div className={classes.phone}>Number of logins</div>
        <div className={classes.phone}>Total interaction time
          {/* eslint-disable-next-line max-len */}
          <Tooltip tooltipId={'TooltipInfo-attribute'} tooltipText='Please note that the Total Interaction Time updates 5 minutes after the last action was taken by a user' />
        </div>
        <div className={classes.status}>User status</div>
        <div className={classes.access}>User access</div>
      </div>
    </div>
  )
}
