/* eslint-disable */
import React from 'react';

export const NoContactsDataIcon = () => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.2" clip-path="url(#clip0_5678_21801)">
                <path d="M31.5 58.5833C46.4577 58.5833 58.5833 46.4577 58.5833 31.5C58.5833 16.5423 46.4577 4.41667 31.5 4.41667C16.5422 4.41667 4.41663 16.5423 4.41663 31.5C4.41663 46.4577 16.5422 58.5833 31.5 58.5833Z" stroke="#B0B2C0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M31.5 20.6667V31.5" stroke="#B0B2C0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M31.5 42.3333H31.5271" stroke="#B0B2C0" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_5678_21801">
                    <rect width="64" height="64" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}
