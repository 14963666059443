import { BRANDEIS_BLUE, MEDIUM_GREEN } from 'constants/colors'
import styled from 'styled-components'

const EditIconWrap = styled.button<{ wasEdited: boolean }>`
  all: unset;

  & svg path {
    fill: ${BRANDEIS_BLUE};
    fill: ${({ wasEdited }) => (wasEdited ? MEDIUM_GREEN : BRANDEIS_BLUE)};
  }

  & svg {
    ${({ wasEdited }) => wasEdited && 'height: 20px; width: 20px'};
  }
`
export { EditIconWrap }
