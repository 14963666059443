import React from 'react'
import Button from '@material-ui/core/Button'
import {PlusIcon} from '../../../uiKit/icons/Icons'
import {openInviteUserModal} from '../actions/contactsModalsAction'
import classes from './styles.module.scss'

export const ContactsTabHeader: React.FC = ({}) => {

  return (
    <div className={classes.headerWrapper}>
      <h1 className={classes.title}>
                    Contacts
      </h1>
      <div className={classes.buttonWrapper}>
        <Button
          className={classes.addNewUserButton}
          onClick={e => openInviteUserModal()}
          variant="raised">
          <PlusIcon height="13px" width="13px" color="#FFFFFF"/>
        </Button>
        <p className={classes.addTitle}>
                        Invite new user
        </p>
      </div>
    </div>
  )
}
