import React, {CSSProperties} from 'react'
import {InfoFieldValue} from './InfoFieldValue'
import {InfoFieldTitle} from './InfoFieldTitle'
import classes from './styles.module.scss'

interface InfoFieldProps {
  title: string
  value: string
  link? : boolean
  styles?: CSSProperties
}

export const InfoField: React.FC<InfoFieldProps> = ({link, title, value, styles = {}}) => {
  return (
    <div className={classes.infoFiledWrapper} style={styles}>
      <InfoFieldTitle title={title}/>
      <InfoFieldValue value={value} link={link}/>
    </div>
  )
}
