import React, { FC, useState, ChangeEvent } from 'react'

import Search from 'uiKit/Search/Search'

import * as S from './SourcesSubPages.style'
import { DetectOverflow } from '../DetectOverflow/DetectOverflow'

const searchStyle = { maxWidth: '420px' }
const inputStyle = { minHeight: '46px', borderRadius: '10px' }

export const SourcesSubPages: FC<{ title: string; subPages: string[] }> = ({ title, subPages }) => {
  const [searchSubPage, setSearchSubPage] = useState('')

  const filteredSubPages = subPages.filter(subPage => subPage.toLowerCase().includes(searchSubPage.toLowerCase()))

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearchSubPage(target.value)
  }

  const handleLinkClick = (event, url: string) => {
    event.preventDefault()
    window.open(url, '_blank')
  }

  return (
    <>
      <Search style={searchStyle} inputStyle={inputStyle} onChange={handleSearch} />
      <S.Container>
        <S.Title>{title}</S.Title>
        {filteredSubPages.map(subPageURL => (
          <div key={subPageURL} style={{ flex: '1 1 100%', minWidth: 0, display: 'grid', gap: '12px' }}>
            <S.HideOverflow>
              <S.LinkContainer>
                <DetectOverflow withTooltip={false}>
                  <S.Link key={subPageURL} onClick={event => handleLinkClick(event, subPageURL)} href={subPageURL}>
                    {subPageURL}
                  </S.Link>
                </DetectOverflow>
              </S.LinkContainer>
            </S.HideOverflow>
          </div>
        ))}
      </S.Container>
    </>
  )
}
