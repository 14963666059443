import { store } from '../../../index'

export const SAVE_CONTACTS_SIZE = 'SAVE_CONTACTS_SIZE'
export const ADD_CONTACT_SIZE = 'ADD_CONTACT_SIZE'

export const saveContactsSize = contactsSize => {
  store.dispatch({
    type: SAVE_CONTACTS_SIZE,
    contactsSize,
  })
}

export const addContactsSize = contactsSize => {
  store.dispatch({
    type: ADD_CONTACT_SIZE,
    contactsSize,
  })
}
