export const styles = () => ({
  attributeWrap: {
    display: 'flex',
    flexFlow: 'row',
    marginBottom: 9,
    '&:hover > div': {
      display: 'flex',
    },
  },
  attributeNameWrap: {
    width: 194,
    display: 'flex',
    alignItems: 'center',
    height: 38,
  },
  inputWrap: {
    position: 'relative',
  },
  input: {
    border: '1px solid rgba(53, 64, 82, 0.1)',
    outline: 'none',
    height: 38,
    borderRadius: 4,
    padding: '8px 12px',
    color: 'rgba(53, 64, 82, 0.7)',
  },
  warning: {
    position: 'absolute',
    color: '#FF6666',
    fontSize: 11,
    lineHeight: 1
  },
  attributeName: {
    backgroundColor: '#5A98F7',
    color: '#ffffff',
    borderRadius: 50,
    padding: '1px 8px',
    fontSize: 14,
    height: 24,
    width: 'fit-content',
    margin: 0,
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  iconWrap: {
    cursor: 'pointer',
    display: 'none',
    alignItems: 'center',
    marginLeft: 4,
  },
  tooltip: {
    boxSizing: 'border-box',
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    borderRadius: '10px',
    zIndex: 9999,
    opacity: 1,
    textAlign: 'start',
    fontWeight: 'normal',
    padding: 10,
    backgroundColor: '#616581',
  },
})
