import React, {useState} from 'react'
import {CustomButton} from '../../common/CustomButton'
import {ScheduleResultsDeliveryModal} from '../../modal/ScheduleResultsDeliveryModal'
import Modal from '../../../../../uiKit/Modal'
import classes from './styles.module.scss'
import {ContactInfo, GtKitInfo, GtKitOption, ResultsDeliveryInfo} from '../../../api/model/ContactInfo'
import {connect} from 'react-redux'
import ResultStatusComponent from './ResultStatusComponent'
import {formatDate} from '../confirmResultsSection/ConfirmResultsSection'
import {ReScheduleResultsModal} from '../../modal/ReScheduleResultsModal'

interface PostTestSectionProps {
    match: any
    contactInfo: ContactInfo
    gtKitInfo: GtKitInfo
    resultsDeliveryInfo: ResultsDeliveryInfo
}

const PostTestSection: React.FC<PostTestSectionProps> = ({match, contactInfo, gtKitInfo, resultsDeliveryInfo}) => {
  const [scheduleModalOpen, setScheduleModalOpen] = useState<boolean>(false)
  const [reScheduleModalOpen, setReScheduleModalOpen] = useState<boolean>(false)

  const {
    isRequiredStatus
  } = contactInfo

  const {
    gtKitOption
  } = gtKitInfo

  const {
    isResultsDeliveryScheduled,
    resultStatus,
    positiveResultsDeliveryConfirmationTimestamp,
    rescheduleResultDelivery
  } = resultsDeliveryInfo

  const handleClick = (e) => {
    setScheduleModalOpen(true)
  }

  const handleModalClose = () => {
    setScheduleModalOpen(false)
  }

  const handleReScheduleModalClose = () => {
    setReScheduleModalOpen(false)
  }

  return (
    <div className={classes.wrapper}>
      <Modal
        classes={{paper: classes.paperModal}}
        open={scheduleModalOpen}
        onClose={handleModalClose}>
        <ScheduleResultsDeliveryModal
          match={match}
          onClose={handleModalClose}
        />
      </Modal>
      <Modal
        classes={{paper: classes.paperModal}}
        open={reScheduleModalOpen}
        onClose={handleReScheduleModalClose}
      >
        <ReScheduleResultsModal
          match={match}
          onClose={handleReScheduleModalClose}
        />
      </Modal>
      <span className={classes.text}>
          Post-test
      </span>
      <div className={classes.wrapperBottom} style={{ width: '600px' }}>
        <div className={classes.wrapperBottomLeft}>
          <span className={classes.textLight}>
          Deliver test results
          </span>
          {isResultsDeliveryScheduled && <ResultStatusComponent resultStatus={resultStatus}/>}
        </div>
        <div className={classes.wrapperBottom} style={{ width: '1300px' }}>
          {isResultsDeliveryScheduled ? (
            <>
              <div
                className={classes.resultDeliveryContainer}
              >
                Results delivery scheduled
                <span
                  className={classes.dateTime}
                >
                  {formatDate(positiveResultsDeliveryConfirmationTimestamp)}
                </span>
              </div>
              <CustomButton
                text='Reschedule'
                disabled={!rescheduleResultDelivery}
                fulled={false}
                onBtnClick={setReScheduleModalOpen}
              />
            </>
          ) : (
            <CustomButton
              text='Schedule results delivery'
              disabled={!isRequiredStatus || gtKitOption != GtKitOption.CONFIRMED}
              fulled={isResultsDeliveryScheduled}
              onBtnClick={handleClick}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  contactInfo: state.contactInfo,
  gtKitInfo: state.gtKitInfo,
  resultsDeliveryInfo: state.resultsDeliveryInfo,
})

export default connect(mapStateToProps)(PostTestSection)
