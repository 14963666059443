export const styles = () => ({
  container: {
    width: 124,
    height: 36,
    backgroundColor: '#1658F3',
    color: '#ffffff',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    margin: '0px 12px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
})
