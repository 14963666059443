import styled from 'styled-components'

const RadioButtonGroup = styled.div`
  width: 400px;
  display: flex;
  margin: 24px 0;
`

const Option = styled.div<{ isActive: boolean }>`
  border: 1px solid ${({ isActive }) => (isActive ? '#5a98f7' : '#c7cad6')};
  background-color: ${({ isActive }) => (isActive ? '#5a98f7' : '#ffffff')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 115px;
  color: ${({ isActive }) => (isActive ? '#ffffff' : '#616581')};
  flex-grow: 1;
  flex-basis: 0;
  font-size: 14px;
  cursor: pointer;
  &:first-child{
    border-radius: 10px 0 0 10px;
  }
  &:last-child{
    border-radius: 0 10px 10px 0;
    flex-grow: 0.5;
  }
`

export {RadioButtonGroup, Option}
