import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Headline from '../../uiKit/texts/Headline'
import Submenu from '../../uiKit/submenu/Submenu'
import LoaderScreen from '../../uiKit/loaders/loaderScreen'
import { widgetRoutes } from '../../routes/widgetRoutes'
import { HOME_PATH } from '../../configs'
import { getWidget, getWidgetDefaultSettings } from './api/widget'
import { setBrowserTabTitle } from '../../helpers/setBrowserTabTitle'

interface Props {
  activeBot: any
  match: any
}

const WidgetContainer: React.FC<Props> = ({ activeBot, match }) => {
  const [loader, setLoader] = useState(true)
  const pathLink = `${HOME_PATH}/bot/${activeBot?.id}/widget`
  const pathRoute = `${HOME_PATH}/bot/:botId/widget/`

  useEffect(() => {
    getWidgetDefaultSettings(match.params.botId)
    getWidget(match.params.botId).then(() => setLoader(false))
  }, [])

  useEffect(() => {
    setBrowserTabTitle(`${activeBot?.name} - Widget`)
  }, [activeBot])

  return (
    <>
      {loader ? (
        <LoaderScreen />
      ) : (
        <>
          <Headline title="Chatbot Widget" />
          <Submenu pathLink={pathLink} pathRoute={pathRoute} routes={widgetRoutes} />
        </>
      )}
    </>
  )
}

const mapStateToProps = (state: { activeBot: any }) => ({
  activeBot: state.activeBot,
})

export default withRouter(connect(mapStateToProps)(WidgetContainer))
