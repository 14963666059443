
export const EMAIL = 'email'
export const NUMBER = 'number'
export const TEXT = 'text'
export const LIVING_UNAFFECTED_FEMALE = 'livingUnaffectedFemale'
export const LIVING_UNAFFECTED_MALE = 'livingUnaffectedMale'
export const DECEASED_FEMALE = 'deceasedFemale'
export const DECEASED_MALE = 'deceasedMale'
export const UNKNOWN_LIVING_DECEASED_STATUS = 'unknownLivingDeceasedStatus'
export const LIVING_AFFECTED_FEMALE = 'livingAffectedFemale'
export const LIVING_AFFECTED_MALE = 'livingAffectedMale'
export const DECEASED_AFFECTED_FEMALE = 'deceasedAffectedFemale'
export const DECEASED_AFFECTED_MALE = 'deceasedAffectedMale'
