export const styles = () => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  listItem: {
    border: '1px solid #f3f3f3',
    width: 94,
    margin: 0,
    padding: '18px 6px 14px',
    boxSizing: 'border-box',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#3A3F62',
    fontSize: 12,
    '&:hover': {
      background: '#5A98F7',
      color: 'white',
      border: '1px solid #5A98F7',
    },
    '&:hover svg path': {
      stroke: 'white',
    },
    '&:first-child': {
      borderBottomLeftRadius: 10,
      borderTopLeftRadius: 10,
    },
    '&:last-child': {
      borderBottomRightRadius: 10,
      borderTopRightRadius: 10,
      width: 32,
      justifyContent: 'center'
    },
  },
})
