import React from 'react'
import classes from './styles.module.scss'
import {ContactInfo, ResultStatus} from '../../../api/model/ContactInfo'
import {connect} from 'react-redux'

interface ResultStatusComponentProps {
    resultStatus: ResultStatus
}

const ResultStatusComponent: React.FC<ResultStatusComponentProps> = ({resultStatus}) => {

  switch (resultStatus) {
    case ResultStatus.NEGATIVE:
      return (
        <div className={classes.negativeResult}>
                  Negative
        </div>
      )
    case ResultStatus.POSITIVE:
      return (
        <div className={classes.positiveResult}>
                  Positive
        </div>
      )
    case ResultStatus.VUS:
      return (
        <div className={classes.vusResult}>
                  VUS
        </div>
      )
    default: throw new Error('Unsupported resultStatus: ' + resultStatus)
  }
}

const mapStateToProps = (state: { contactInfo: ContactInfo }) => ({
  contactInfo: state.contactInfo,
})

export default connect(mapStateToProps)(ResultStatusComponent)
