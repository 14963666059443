import React from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import TablePaginationActionsWrapped from '../../../../../uiKit/table/TablePaginationActionsWrapped'
import classes from './styles.module.scss'

interface ContactsTablePaginationProps {
  count: number
  page: number
  rowsPerPage: number
  onChangePage: (e, page) => void
}

export const ContactsTablePagination: React.FC<ContactsTablePaginationProps> = ({
  count,
  page,
  rowsPerPage,
  onChangePage
}) => {
  return (
    <div className={classes.paginationWrapper}>
      <TablePagination
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        classes={{
          caption: classes.caption,
          root: classes.root
        }}
        onChangePage={onChangePage}
        ActionsComponent={TablePaginationActionsWrapped}
        SelectProps={{
          style: {
            display: 'none',
          },
        }}
      />
    </div>
  )
}
