import React from 'react'
import {ViewIcon} from '../../../common/icons/ViewIcon'
import {DownloadIcon} from '../../../common/icons/DownloadIcon'
import classes from './styles.module.scss'

interface InsuranceCardPhotoButtonsProps {
  photoUrl?: string
}

export const InsuranceCardPhotoButtons: React.FC<InsuranceCardPhotoButtonsProps> = ({photoUrl}) => {

  const download = (url: string) => {
    const splitURL = url.split('/')
    const fileName = splitURL[splitURL.length - 1]
    fetch(url)
      .then(response => {
        return response.blob()
      })
      .then(blob => {
        const blobURL = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = blobURL
        a.download = fileName
        a.target = '_blank'

        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
  }

  return (
    <div className={classes.buttonsWrapper}>
      <div className={photoUrl ? classes.downloadButton : classes.downloadButtonDisabled}
        onClick={e => {
          if (photoUrl) download(photoUrl)
        }}>
        <DownloadIcon disabled={!photoUrl}/>
        <div className={photoUrl ? classes.text : classes.textDisabled}>
                    Download
        </div>
      </div>
      <a
        href={photoUrl}
        target={'_blank'}
        className={photoUrl ? classes.viewButton : classes.viewButtonDisabled}
        rel='noreferrer'>
        <div className={photoUrl ? classes.viewButton : classes.viewButtonDisabled}>
          <ViewIcon disabled={!photoUrl}/>
          <div className={photoUrl ? classes.text : classes.textDisabled}>
                    View
          </div>
        </div>
      </a>
    </div>
  )
}
