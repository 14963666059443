import {BACKEND_URL} from '../../../../../configs'

type FamilyTreeResponse = {
  url: string
}

export type TreeEdge = {
  id: string
  source: string
  sourceHandle: string
  target: string
  targetHandle: string
}

export type TreeNode = {
  data?: {
    diagnoses?: string
    relation?: string
    relativeInfo: string
    status: string
  }
  id: string
  position: {
    x: number
    y: number
  }
  type: string
}

export type TreeOrigins = {
  maternal: {
    countries: string[],
    ancestry: string
  },
  paternal: {
    countries: string[],
    ancestry: string
  }
}

type FamilyTreeType = {
  edges: TreeEdge[]
  nodes: TreeNode[]
  origins: TreeOrigins
}
export const getFamilyTreeInfo = async (chatId: string): Promise<FamilyTreeType> => {
  try {
    const result = await fetch(`${BACKEND_URL}/family-tree?chatId=${chatId}`, {
      credentials: 'include',
      method: 'GET',
    })
    if (result.ok) {
      return result.json()
    }
  } catch (e) {
    console.log(e)
  }
}


export const sendFamilyTreeFile = async (chatId: string, file: FormData): Promise<FamilyTreeResponse> => {
  try {
    const res = await fetch(`${BACKEND_URL}/save-family-tree-file?chatId=${chatId}`, {
      credentials: 'include',
      method: 'POST',
      body: file
    })
    if (res.ok) {
      return res.json()
    }
  } catch (e) {
    console.log(e)
  }
}
