import React, {FC, MouseEvent} from 'react'
import './custom-node.scss'

interface NodeData {
    name: string;
    current: boolean;
    link: string;
    relation: string;
}

interface MyNodeProps {
    nodeData: NodeData;
}

export const CustomNode: FC<MyNodeProps> = ({nodeData}) => {
  const selectNode = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (!nodeData.current) {
      window.open(nodeData.link, '_blank')
    }

  }

  return (
    <div onClick={selectNode}>
      <div className={`username ${nodeData.current ? 'current' : ''}`}>
        <span style={{
          color: 'black'
        }}>User name: </span>{nodeData.name}
        <br></br>
        <span style={{
          color: 'black'
        }}>{nodeData.relation ? `Relationship: ${nodeData.relation}` : ''}</span>
      </div>
    </div>
  )
}
