import React from 'react'

export const BackToAllContactsArrow = () => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 7L1 7" stroke="#3A3F62" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7 1L1 7L7 13" stroke="#3A3F62" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
