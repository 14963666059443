import React from 'react'
import classes from './styles.module.scss'
import { DownloadIcon } from '../../common/icons/DownloadIcon'

interface ChatBotDeliverablesButtonsProps {
  excelLink: string
  username: string
}

export const ChatBotDeliverablesButtonsExcel: React.FC<ChatBotDeliverablesButtonsProps> = ({
  excelLink,
  username
}) => {

  const onClickHandler = () => {
    const isReadinessRuler = excelLink.includes('readiness-ruler')
    const fileName = `${username}_${isReadinessRuler ? 'Readiness_Ruler' : 'FPQ'}.xlsx`

    fetch(excelLink, {method: 'GET'})
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          fileName
        )
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      })
  }

  return (
    <div className={classes.buttonsWrapper}>
      <a
        onClick={onClickHandler}
        className={excelLink ? classes.viewButton : classes.viewButtonDisabled}
      >
        <DownloadIcon disabled={!excelLink}/>
        <div className={excelLink ? classes.text : classes.textDisabled}>
          Download Excel
        </div>
      </a>
    </div>
  )
}
