import styled from 'styled-components'

const Container = styled.div`
  height: 100%;
`

const SubHeader = styled.div`
  font-size: 14px;
  color: #616581;
`

export { Container, SubHeader }
