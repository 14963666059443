import React from 'react'

import * as S from './UploadForm.style'
import { TrainingStatus } from '../../../../settings/constants/trainingStatus'

export const UploadForm = ({ onUpload, trainingStatus }) => {
  return (
    <S.UploadForm>
      <S.Button htmlFor="upload">Upload files</S.Button>
      <S.File id="upload" type="file" accept=".pdf, .txt, .doc" onChange={onUpload} />
      <S.Title>Files should be PDF, TXT, Doc</S.Title>
      {trainingStatus === TrainingStatus.PENDING && <S.DisableScreen />}
    </S.UploadForm>
  )
}
