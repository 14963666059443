import React from 'react'
import { useForm } from 'react-hook-form/dist/index.ie11'

import { EditPageGeneral } from '../EditPageGeneral'
import { useFacebookActivePagesContext } from '../FacebookActivePages/context'
import { useDisconnectPageModalContext } from '../DisconnectPageModal/context'
import SubmitButton from 'uiKit/buttons/SubmitButton'
import LoaderSmall from 'uiKit/loaders/loaderSmall'

import { alertSuccess, alertError } from 'api'

interface Props {
  classes: any
  page: any
  botId: number
  closeEditModal: () => void
}

export const EditPage: React.FC<Props> = props => {
  const { classes } = props
  const { editFacebookPage, isLoading, getPages } = useFacebookActivePagesContext()
  const { handleOpenDisconnectPageModal } = useDisconnectPageModalContext()
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: props.page,
    shouldUnregister: false,
  })

  const onSubmit = data => {
    editFacebookPage(props.botId, data)
      .then(() => {
        alertSuccess('Page settings updated')
        getPages(props.botId)
      })
      .catch(error => alertError(error.message || 'Edit subscribed page error'))
      .finally(() => props.closeEditModal())
  }

  const handleDisconnect = e => {
    e.preventDefault()
    handleOpenDisconnectPageModal(props.page)
  }

  const handleCancel = e => {
    e.preventDefault()
    props.closeEditModal()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      {isLoading ? (
        <div className={classes.loaderScreen}>
          <LoaderSmall showLoader={true} />
        </div>
      ) : (
        <EditPageGeneral control={control} page={props.page} setValue={setValue} />
      )}
      <div className={classes.buttons}>
        <SubmitButton title="Disconnect" disabled={isLoading} onClick={handleDisconnect} type="warning" />
        <SubmitButton
          empty
          title="Cancel"
          onClick={handleCancel}
          styles={{ borderRadius: 4, marginRight: 8, marginLeft: 'auto' }}
        />
        <SubmitButton title="Save" disabled={isLoading} styles={{ borderRadius: 4 }} />
      </div>
    </form>
  )
}
