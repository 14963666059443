import React from 'react'
import classes from './styles.module.scss'

interface InfoFieldTitleProps {
  title: string
}

export const InfoFieldTitle: React.FC<InfoFieldTitleProps> = ({title}) => {
  return (
    <div className={classes.infoFiledTitle}>
      {title}
    </div>
  )
}
