import styled from 'styled-components'
import { PERMISSION_RED } from 'constants/colors'

const Container = styled.div<{ index: number }>`
  display: flex;
  align-items: center;
  gap: 4px;
`

const Text = styled.div`
  color: ${PERMISSION_RED};
  font-size: 16px;
`

export { Container, Text }
