import React from 'react'
import {InfoField} from '../../common/InfoField'
import {preparePhoneNumber} from '../../leftBlock/general_info/GeneralInfoSection'
import {ContactInfo} from '../../../api/model/ContactInfo'
import {connect} from 'react-redux'

interface PersonalInfoSectionProps {
    contactInfo: ContactInfo
}

const PersonalInfoSection: React.FC<PersonalInfoSectionProps> = ({contactInfo}) => {
  const {
    nameFromArguments,
    surnameFromArguments,
    emailFromArguments,
    phoneFromArguments,
    addressFromArguments,
    resultsDeliverWay
  } = contactInfo
  return (
    <>
      <InfoField
        title={'Name'}
        value={nameFromArguments || 'NONE'}
      />
      <InfoField
        title={'Surname'}
        value={surnameFromArguments || 'NONE'}
      />
      <InfoField
        title={'Email address'}
        value={emailFromArguments || 'NONE'}
      />
      <InfoField
        title={'Phone number'}
        value={phoneFromArguments ? preparePhoneNumber(phoneFromArguments) : 'NONE'}
      />
      <InfoField
        title={'Address'}
        value={addressFromArguments || 'NONE'}
      />
      <InfoField
        title={'How to deliver lab results'}
        value={resultsDeliverWay || 'NONE'}
      />
    </>
  )
}

const mapStateToProps = (state: { contactInfo: ContactInfo }) => ({
  contactInfo: state.contactInfo,
})

export default connect(mapStateToProps)(PersonalInfoSection)
