/**
 * Created by Admin on 26.02.2018.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import { Checkbox, FormControlLabel } from '@material-ui/core'

class HoursSpecification extends React.Component {
  render() {
    const { classes, days, type, label, timeList } = this.props
    return (
      <div className={classes.dateContainer}>
        <FormControlLabel
          className="checkbox"
          control={
            <Checkbox
              onChange={() => this.props.handleSet(type)}
              value={this.props.value.toString()}
              checked={this.props.value}
              color="primary"
            />
          }
          label={label.charAt(0).toUpperCase() + label.slice(1)}
        />
        <span style={{ marginTop: -10 }}>from</span>
        <div style={{ width: 135, margin: '-10px 16px 0 16px' }}>
          <Select
            onBlurResetsInput={false}
            onSelectResetsInput={false}
            options={timeList}
            simpleValue
            value={timeList.find(
              time =>
                time.value ===
                (days && days.startTime != null ? days.startTime : '09:00'),
            )}
            name="selected-state"
            onChange={event =>
              this.props.onChange(event.value, 'startTime', label)
            }
            searchable
          />
        </div>
        <span style={{ marginTop: -10 }}>to</span>
        <div style={{ width: 135, margin: '-10px 16px 0 16px' }}>
          <Select
            options={timeList}
            simpleValue
            value={timeList.find(
              time =>
                time.value ===
                (days && days.endTime != null ? days.endTime : '18:00'),
            )}
            onChange={event =>
              this.props.onChange(event.value, 'endTime', label)
            }
            searchable
          />
        </div>
      </div>
    )
  }
}

HoursSpecification.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
})

export default withRouter(connect(mapStateToProps)(HoursSpecification))
