import React from 'react'
import ReactTooltip from 'react-tooltip'

import { ArrowRotate } from 'uiKit/icons/ArrowRotate'

import * as S from './TrainingStatus.style'

export const TrainingStatus = () => {
  return (
    <>
      <S.Container data-tip data-for="tooltip-pending">
        <ArrowRotate />
        <S.Text>Bot is training</S.Text>
      </S.Container>
      <ReactTooltip place="top" effect="solid" id="tooltip-pending">
        Training may take some time
      </ReactTooltip>
    </>
  )
}
