import Modal from '@material-ui/core/Modal'
import React, { FC, ReactEventHandler } from 'react'

import { CrossIcon } from 'uiKit/icons/CrossIcon'
import SubmitButton from 'uiKit/buttons/SubmitButton'
import { SPUN_PEARL_GREY } from 'constants/colors'
import { alertError, alertSuccess } from 'api'
import { deleteApiKey } from '../../../api/apiKeys'

import * as S from './DeleteApiKeysModal.style'

interface Props {
  activeBot: any
  open: boolean
  selectedApiKey: string
  onClose: ReactEventHandler
  updateApiKeysList: () => void
}

export const DeleteApiKeysModal: FC<Props> = ({ activeBot, open, selectedApiKey, onClose, updateApiKeysList }) => {
  const handleDelete = (event: any) => {
    deleteApiKey(activeBot.id, selectedApiKey)
      .then(() => {
        updateApiKeysList()
        alertSuccess('API key is deleted successfully')
      })
      .catch(error => alertError(error?.message || 'Fail. Try it later'))
      .finally(() => onClose(event))
  }

  return (
    <Modal open={open} onClose={onClose}>
      <S.Container>
        <S.Title>Are you sure you want to delete "{selectedApiKey}" API key?</S.Title>
        <S.SubTitle>Deleting this API key will immediately revoke it's access.</S.SubTitle>
        <S.IconWrap onClick={onClose}>
          <CrossIcon color={SPUN_PEARL_GREY} />
        </S.IconWrap>
        <S.ButtonsWrap>
          <SubmitButton onClick={onClose} title="Cancel" />
          <S.DeleteButton onClick={handleDelete}>Delete</S.DeleteButton>
        </S.ButtonsWrap>
      </S.Container>
    </Modal>
  )
}
