import React, { useEffect, useState } from 'react'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { alertSuccess, alertError, alertWarning } from 'api'
import Input from 'uiKit/inputs/Input'
import { login, connectDF } from '../../../api/dialogflow'
import { isStringEmpty } from 'helpers/isStringEmpty'
import { getBot } from 'tabs/home/api/bots'

import * as S from './DialogflowIntegration.style'
import { FAILED_MESSAGE, PENDING_MESSAGE, RESOLVED_MESSAGE } from '../../../constants/alerts'

interface Props {
  activeBot: any
}

export const DialogflowIntegration: React.FC<Props> = ({ activeBot }) => {
  const [connectionType, setConnectionType] = useState('existing')
  const [projectId, setProjectId] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [botProjectId, setBotProjectId] = useState(activeBot?.dialogflowConfigs?.projectId || null)

  useEffect(() => {
    const botProjectId = activeBot?.dialogflowConfigs?.projectId

    if (botProjectId) {
      setProjectId(botProjectId)
      setBotProjectId(botProjectId)
    }
  }, [activeBot])

  const handleSelectConnection = e => {
    setError(false)
    setConnectionType(e.target.value)
    setProjectId('')
  }

  const handleConnectExisting = () => {
    if (isValidForm()) {
      setLoading(true)
      login(activeBot.id, projectId)
        .then(() => {
          setLoading(false)
        })
        .catch(json => {
          setLoading(false)
          alertError(json?.error?.message || FAILED_MESSAGE)
        })
    }
  }

  const handleConnectNew = () => {
    setLoading(true)
    alertWarning(PENDING_MESSAGE)

    connectDF(activeBot.id)
      .then(() => {
        setLoading(false)
        alertSuccess(RESOLVED_MESSAGE)
        getBot(activeBot.id)
      })
      .catch(json => {
        setLoading(false)
        alertError(json?.error?.message || FAILED_MESSAGE)
      })
  }

  const isValidForm = () => {
    const error = isStringEmpty(projectId)
    setError(error)

    return !error
  }

  return (
    <S.DialogflowIntegration>
      <RadioGroup value={connectionType} onChange={handleSelectConnection}>
        {!botProjectId && (
          <FormControlLabel
            value={'existing'}
            control={<Radio color="primary" />}
            label={'Connect existing agent'}
            disabled={loading}
          />
        )}
        <Input
          error={error}
          value={projectId}
          maxLength={255}
          autofocus
          placeholder="Enter Google Project ID"
          onChange={event => setProjectId(event.target.value)}
          disabled={connectionType === 'new' || botProjectId || loading}
        />
        {error ? (
          <S.Error>This field can’t be empty</S.Error>
        ) : (
          <S.Label>Find Google Project ID in DialogFlow Settings page</S.Label>
        )}

        <S.SubmitButtonStyled
          title="Connect"
          type={'secondary'}
          size="sm"
          onClick={handleConnectExisting}
          disabled={connectionType === 'new' || botProjectId || loading || !projectId.length}
          isMarginBottom={!botProjectId}
        />

        {!botProjectId && (
          <FormControlLabel
            value={'new'}
            control={<Radio color="primary" />}
            label={'Create and connect new agent'}
            disabled={loading}
          />
        )}
        {!botProjectId && (
          <S.SubmitButtonStyled
            title="Create"
            type={'secondary'}
            size="sm"
            onClick={handleConnectNew}
            disabled={connectionType === 'existing' || loading}
          />
        )}
      </RadioGroup>
    </S.DialogflowIntegration>
  )
}
