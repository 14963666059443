import { store } from '../../../index'
import {ContactInfo} from '../api/model/ContactInfo'

export const SAVE_CONTACT_INFO = 'SAVE_CONTACT_INFO'
export const CLEAR_CONTACT_INFO = 'CLEAR_CONTACT_INFO'

export const saveContactInfo = (contactInfo: ContactInfo) => {
  store.dispatch({
    type: SAVE_CONTACT_INFO,
    contactInfo,
  })
}

export const clearContactInfo = () => {
  store.dispatch({
    type: CLEAR_CONTACT_INFO,
  })
}
