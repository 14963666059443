import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import uuidv1 from 'uuid/v1'
import AddActionModal from '../AddActionModal/AddActionModal'
import { MoreIcon } from '../../../../uiKit/icons/MoreIcon'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import { alertError } from '../../../../api'
import {
  apiCall,
  availableDefaultMessages,
  setAttributeMessage,
  smartRedirect
} from '../messages/AvailableMessages'
import {
  API_CALL_TYPE,
  CONDITIONAL_REDIRECT_TYPE,
  RANDOM_REDIRECT_TYPE,
  SAVE_USER_INPUT_TYPE,
  SET_ATTRIBUTE_TYPE
} from '../../constants/messageTypes'
import { styles } from './styles'

const NewMessage = props => {
  const { classes, atom, updateAtom } = props
  const [open, setOpen] = useState(false)

  const handleCreateNewMessage = newMessage => {
    const newAtom = { ...atom }

    if (!newAtom.messages) newAtom.messages = []
    newMessage['tempId'] = uuidv1()
    newAtom.messages.push({ ...newMessage })

    updateAtom(newAtom)
  }

  const onClickHandler = (message) => {
    const newAtom = { ...atom }
    if (!newAtom.messages) newAtom.messages = []
    switch (message.type) {
      case CONDITIONAL_REDIRECT_TYPE:
        if (
          !newAtom.messages.some(
            message => message.type === RANDOM_REDIRECT_TYPE ||
              message.type === CONDITIONAL_REDIRECT_TYPE ||
              message.type === SAVE_USER_INPUT_TYPE)
        ) {
          const newMessage = deepCopyFunction(smartRedirect)
          newMessage['tempId'] = uuidv1()
          newAtom.messages.push(JSON.parse(JSON.stringify(newMessage)))
          updateAtom(newAtom)
        } else {
          alertError(
            'Atom can contain only one of these elements: Smart Redirect atom, Random redirect or Save User Input'
          )
        }
        break
      case SET_ATTRIBUTE_TYPE:
        if (!newAtom.messages.some(message => message.type === SET_ATTRIBUTE_TYPE)) {
          const newMessage = deepCopyFunction(setAttributeMessage)
          newMessage['tempId'] = uuidv1()
          newAtom.messages.push({ ...newMessage })
          updateAtom(newAtom)
        }
        break
      case API_CALL_TYPE:
        const newMessage = deepCopyFunction(apiCall)
        newMessage['tempId'] = uuidv1()
        newAtom.messages.push({ ...newMessage })
        updateAtom(newAtom)
        break
      default:
        handleCreateNewMessage(message.newMessage)
    }
  }

  return (
    <>
      <div className={classes.container}>
        {availableDefaultMessages.map((message, index) => (
          <div
            key={index}
            className={classes.listItem}
            onClick={() => onClickHandler(message)}>
            {message.icon}
            <div>{message.name}</div>
          </div>
        ))}

        <div className={classes.listItem} onClick={() => setOpen(true)}>
          <MoreIcon />
        </div>
      </div>

      <AddActionModal
        open={open}
        atom={atom}
        updateAtom={updateAtom}
        onClose={() => setOpen(false)}
      />
    </>
  )
}
NewMessage.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(NewMessage)
