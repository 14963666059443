import React, { useState } from 'react'
import { connect } from 'react-redux'
import classes from './styles.module.scss'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import { formatApiCall } from '../../../../helpers/formatAtomSave'
import { testApiRequest } from '../../api/atom'
import LoaderSmall from '../../../../uiKit/loaders/loaderSmall'
import { alertError } from '../../../../api'
import UsersSelect from '../../../../uiKit/UsersSelect/UsersSelect'
import {ApiCallType} from '../../../../models/MessageTypes'

interface Props {
  apiCall: ApiCallType
  setResponse(data: any): void
  activeBotId: number
}

const TestRequestScreen: React.FC<Props> = ({ apiCall, setResponse, activeBotId }) => {
  const [loading, setLoading] = useState(false)
  const [userId, setUserId] = useState(null)

  const handleTestRequest = async() => {
    const formattedApiCall = formatApiCall(apiCall)

    if (!formattedApiCall.url) {
      alertError('Enter request url')
      return
    }

    setLoading(true)

    const response = await testApiRequest(formattedApiCall, activeBotId, userId)

    if (response?.data) {
      setResponse(response.data)
    } else {
      alertError(response?.error?.message || 'Failed. Try it later')
    }

    setLoading(false)
  }

  return (
    <div className={classes.container}>
      {loading ? (
        <LoaderSmall showLoader={true} />
      ) : (
        <>
          <img src="/images/platform/request.svg" alt="request" />
          <div className={classes.inputContainer}>
            <div className={classes.inputWrap}>
              <UsersSelect
                value={userId}
                onChange={value => setUserId(value.value)}
              />
            </div>
            <SubmitButton
              title="Test the request"
              onClick={handleTestRequest}
              disabled={!userId}
            />
          </div>
        </>
      )}
    </div>
  )
}
const mapStateToProps = state => ({
  activeBotId: state.activeBot?.id,
})
export default connect(mapStateToProps)(TestRequestScreen)