import React from 'react'
import { withStyles } from '@material-ui/core'
import Select from 'react-select'

import { styles, customStyles } from './styles'
import {ApiCallMethodType} from '../../../../models/MessageTypes'

interface Props {
  value: string
  touched: boolean
  onFocus?: any
  onChange?: any
}

type OptionType = {value: ApiCallMethodType, label: ApiCallMethodType};

const methods: ApiCallMethodType[] = ['POST', 'PUT', 'GET', 'DELETE']
const options: OptionType[] = methods.map((str) => ({ value: str, label: str }))

const RequestTypeSelect: React.FC<Props> = ({
  value,
  touched,
  onFocus,
  onChange,
}) => {
  const getValue = (value: string) => {
    return options.find(option => option.value === value)
  }

  return (
    <div>
      <Select
        styles={customStyles}
        options={options}
        value={getValue(value)}
        maxMenuHeight={200}
        minMenuHeight={200}
        menuPlacement={'auto'}
        onChange={onChange}
        onFocus={onFocus}
      />
    </div>
  )
}

export default withStyles(styles)(RequestTypeSelect)
