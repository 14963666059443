import styled from 'styled-components'
import { FIORD_BLUE } from 'constants/colors'

const Body = styled.div`
  padding: 24px 40px 50px;
  overflow: scroll;
  height: calc(90vh - 205px);
`

const Paragraph = styled.div`
  color: ${FIORD_BLUE};
  margin: 24px 0 8px;
  font-size: 16px;
  font-weight: 600;
`

const LoaderScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 345px;
  width: 100%;
`

export { Body, Paragraph, LoaderScreen }
