import { store } from '../../../index'

export const OPEN_INVITE_USER_MODAL = 'OPEN_INVITE_USER_MODAL'
export const CLOSE_INVITE_USER_MODAL = 'CLOSE_INVITE_USER_MODAL'
export const OPEN_USER_ACCESS_MODAL = 'OPEN_USER_ACCESS_MODAL'
export const CLOSE_USER_ACCESS_MODAL = 'CLOSE_USER_ACCESS_MODAL'

export const openInviteUserModal = () => {
  store.dispatch({
    type: OPEN_INVITE_USER_MODAL,
    isInviteNewUserModalOpen: true,
  })
}

export const closeInviteUserModal = () => {
  store.dispatch({
    type: CLOSE_INVITE_USER_MODAL,
    isInviteNewUserModalOpen: false,
  })
}

export const openUserAccessModal = (isActive: boolean, contactId: number) => {
  store.dispatch({
    type: OPEN_USER_ACCESS_MODAL,
    isUserAccessModalOpen: true,
    isActive,
    contactId
  })
}

export const closeUserAccessModal = () => {
  store.dispatch({
    type: CLOSE_USER_ACCESS_MODAL,
    isUserAccessModalOpen: false,
  })
}
