/**
 * Created by serhiy on 05.03.18.
 */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import { getPagingIntents } from '../api/nlp'
import { PlusIcon, SearchIcon } from '../../../uiKit/icons/Icons.js'
import DeleteIntent from './DeleteIntent.js'
import NoData from './NoNlpData.js'
import Loader from '../../../uiKit/loaders/loader'
import Pagination from '../../../uiKit/table/Pagination'
import { clearIntents } from '../actions/intents'
import IntentModal from './IntentModal'
import * as S from './Intents.style'

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
    background: 'rgb(247, 250, 254)',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  createIntentButton: {
    background: 'linear-gradient(312.17deg, #5A98F7 3.33%, #1658F3 96.71%)',
    boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
    height: 40,
    width: 40,
    padding: 0,
    minWidth: 40,
    borderRadius: 25,
  },
  title: {
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: 24,
    color: '#3A3F62',
  },
  input: {
    padding: '10px',
    border: 'none',
    outline: 'none',
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '19px',
    backgroundColor: 'white',
  },
  inputHolder: {
    border: '1px solid #C7CAD6',
    width: 'max-content',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginRight: 10,
    padding: 10,
    maxHeight: 45,
    minWidth: 400,
  },
  intentItem: {
    padding: 24,
    fontSize: 14,
    display: 'flex',
    fontFamily: 'Lato, sans-serif',
    color: '#616581',
  },
  name: {
    flexBasis: '30%',
    maxWidth: '30%',
    wordWrap: 'break-word',
    padding: '0 10px',
  },
  answer: {
    flexBasis: '50%',
    maxWidth: '50%',
    wordWrap: 'break-word',
    padding: '0 10px',
  },
  editInt: {
    flexBasis: '10%',
    color: '#1658F3',
    cursor: 'pointer',
  },
  deleteInt: {
    flexBasis: '10%',
    color: '#FF624C',
    cursor: 'pointer',
  },
  desc: {
    lineHeight: 17,
    fontSize: 14,
    color: '#3A3F62',
  },
  addTitle: {
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#3A3F62',
  },
})

class Intents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
      open: false,
      modalIntentId: 0,
      searchIntent: '',
    }

    clearIntents()
    getPagingIntents(this.props.match.params.botId, this.props.isFaq, this.state.page, '')
  }
  componentWillReceiveProps() {
    if (this.props.intents != null) {
      if (
        (this.props.intents.length < 10 && this.props.intentsSize > this.state.page * 10 + 10) ||
        this.props.intents.length > 10
      ) {
        getPagingIntents(this.props.match.params.botId, this.props.isFaq, this.state.page, '')
      }
      if (this.props.intents.length === 0 && this.props.intentsSize > 0) {
        getPagingIntents(
          this.props.match.params.botId,
          this.props.isFaq,
          this.state.page === 0 ? 0 : this.state.page - 1,
          '',
        )
      }
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
    getPagingIntents(this.props.match.params.botId, this.props.isFaq, page, this.state.searchIntent)
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  closeIntentModal = () => {
    this.setState({ open: false })
  }

  openIntentModal(id) {
    this.setState({
      modalIntentId: id,
      open: true,
    })
  }

  handleSearchIntent(value) {
    this.setState({ searchIntent: value })
    getPagingIntents(this.props.match.params.botId, this.props.isFaq, 0, encodeURI(value))
  }

  getRowStyle = index => {
    if ((index + 1) % 2 === 1) {
      return { background: '#F7FAFE' }
    } else {
      return {
        background: 'rgba(90, 152, 247, 0.1)',
        borderRadius: 10,
      }
    }
  }

  getContent = isFaq => {
    if (isFaq) {
      return {
        buttonTitle: 'faq',
        tableHeader: 'Answer',
        noDataContainerText:
          "You have no FAQ's yet. Try adding new FAQ to make your bot answer the frequenty asked questions easily.",
        noDataContainerButton: 'Add FAQ',
      }
    } else {
      return {
        buttonTitle: 'intent',
        tableHeader: 'User phrase',
        noDataContainerText:
          'You have no Intents yet. Try adding new Intent to make your bot answer more complicated questions.',
        noDataContainerButton: 'Add Intent',
      }
    }
  }

  render() {
    const { classes, intents, isFaq, botLanguages } = this.props
    const { rowsPerPage, page } = this.state
    const intentsList = intents?.filter(intent => intent.name !== 'Default Fallback Intent')
    const displayTable = intentsList?.length || this.state.searchIntent !== ''
    const tableContent = this.getContent(isFaq)

    return (
      <S.Intents>
        {this.state.open && (
          <IntentModal
            intents={this.props.intents}
            intentsSize={this.props.intentsSize}
            tabIndex="-1"
            modalIntentId={this.state.modalIntentId}
            isFaqModal={isFaq}
            onClose={this.closeIntentModal}
            open={this.state.open}
            botLanguages={botLanguages}
          />
        )}

        <div
          style={{
            display: 'flex',
            alignItems: 'self-start',
            minWidth: 260,
            justifyContent: 'space-between',
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'self-start',
              justifyContent: 'space-between',
              flexBasis: '50%',
            }}>
            <h1 className={classes.title} style={{ marginRight: 32 }}>
              {this.props.tableTitle}
            </h1>
            {intentsList && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}>
                <Button
                  className={classes.createIntentButton}
                  onClick={() => this.openIntentModal(null)}
                  variant="raised">
                  <PlusIcon height="13px" width="13px" color="#FFFFFF" />
                </Button>
                <p className={classes.addTitle} style={{ margin: '0 0 0 16px' }}>
                  Add new <span>{tableContent.buttonTitle}</span>
                </p>
              </div>
            )}
          </div>

          {displayTable && (
            <div className={classes.inputHolder}>
              <input
                className={classes.input}
                placeholder={`Search ${this.props.tableTitle}`}
                onChange={event => this.handleSearchIntent(event.target.value)}
              />
              <SearchIcon width="20" height="20" color="#D8D8D8" />
            </div>
          )}
        </div>

        {!intentsList && <Loader />}

        {intentsList && displayTable ? (
          <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
              <div className={classes.table}>
                <div style={{ background: '#F7FAFE' }}>
                  <div
                    className={classes.intentItem}
                    style={{
                      borderBottom: '1px solid #EBEBEF',
                      background: '#F7FAFE',
                      borderTop: 'none',
                    }}>
                    <div className={classes.name}>Name</div>
                    <div className={classes.answer}>{tableContent.tableHeader}</div>
                  </div>
                  {intentsList
                    .filter(n => n.name?.toUpperCase().indexOf(this.state.searchIntent?.toUpperCase()) !== -1)
                    .map((n, index) => (
                      <div key={n.id || index} style={this.getRowStyle(index)} className={classes.intentItem}>
                        <div className={classes.name}>{n.name}</div>
                        <div className={classes.answer}>{n.faq ? n.answerAtom : n.userPhrase}</div>
                        <div className={classes.editInt} onClick={() => this.openIntentModal(n.id)}>
                          Edit
                        </div>
                        <DeleteIntent
                          intentId={n.id}
                          name={this.props.tableTitle}
                          intentsSize={this.props.intentsSize}
                        />
                      </div>
                    ))}
                </div>
                <Pagination
                  count={this.props.intentsSize || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                />
              </div>
            </div>
          </Paper>
        ) : (
          <div>
            <NoData
              description={tableContent.noDataContainerText}
              onClick={() => this.openIntentModal(null)}
              buttonTitle={tableContent.noDataContainerButton}
            />
          </div>
        )}
      </S.Intents>
    )
  }
}

Intents.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  intents: state.intents,
  intentsSize: state.intentsSize,
  activeBot: state.activeBot,
  botLanguages: state.botLanguages,
})

export default withRouter(withStyles(styles)(connect(mapStateToProps)(Intents)))
