import {SAVE_CONTACTS_SIZE, ADD_CONTACT_SIZE} from '../actions/contactsSizeAction'

export const contactsSize = (state = null, action) => {
  switch (action.type) {
    case SAVE_CONTACTS_SIZE:
      return action.contactsSize
    case ADD_CONTACT_SIZE:
      return action.contactsSize + 1
    default:
      return state
  }
}
