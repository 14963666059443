import styled from 'styled-components'
import { COMET_GREY, OXFORD_BLUE, PERMISSION_RED, WHITE } from 'constants/colors'

const Container = styled.div`
  position: absolute;
  background-color: ${WHITE};
  padding: 40px 60px;
  outline: none;
  width: 500px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled.p`
  font-size: 16px;
  color: ${OXFORD_BLUE};
  font-weight: 600;
  text-align: center;
  margin-bottom: 8px;
  word-break: break-word;
`

const SubTitle = styled.p`
  font-size: 12px;
  color: ${COMET_GREY};
  text-align: center;
`

const IconWrap = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`

const ButtonsWrap = styled.div`
  display: flex;
  gap: 8px;
`

const DeleteButton = styled.div`
  font-size: 14px;
  padding: 10px 16px;
  color: ${PERMISSION_RED};
  cursor: pointer;
`

export { Container, Title, IconWrap, ButtonsWrap, DeleteButton, SubTitle }
