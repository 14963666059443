import React, { FC, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import AnalyticsWrap from '../AnalyticsWrap'
import FunnelsWrap from '../FunnelsWrap'
import DateControl from 'uiKit/DateControlNew'
import Headline from 'uiKit/texts/Headline'
import LoaderScreen from 'uiKit/loaders/loaderScreen'

import { getBot } from '../../../home/api/bots'
import { getFlows } from '../../../flowBuilder/api/flow'
import { clearFunnelsState } from '../../actions/funnels'
import { AllAnalyticsType } from 'models/AnalyticsType'
import { FunnelType } from 'models/FunnelTypes'
import { DateControlProvider } from 'contexts/DateControlContext'

import * as S from './Analytics.style'

interface Props {
  match: any
  analytics: AllAnalyticsType
  funnels: FunnelType[]
  botId: number
}

const Analytics: FC<Props> = ({ match, botId }) => {
  const containerEndRef = useRef(null)
  const containerRef = useRef(null)

  useEffect(() => {
    containerRef.current.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    getBot(match.params.botId)
    getFlows(match.params.botId)
    return clearFunnelsState
  }, [])

  const handleScrollToBottom = () => {
    if (containerEndRef.current) {
      containerEndRef.current.scrollIntoView()
    }
  }

  return (
    <S.Container ref={containerRef} loading={!botId}>
      <DateControlProvider>
        <S.HeadlineWrap>
          <Headline title="Analytics" />
          <DateControl />
        </S.HeadlineWrap>

        {botId ? (
          <>
            <AnalyticsWrap />
            <FunnelsWrap scrollToBottom={handleScrollToBottom} />
          </>
        ) : (
          <LoaderScreen />
        )}

        <div ref={containerEndRef} />
      </DateControlProvider>
    </S.Container>
  )
}

const mapStateToProps = (state: { analytics: AllAnalyticsType; funnels: FunnelType[]; activeBot: any }) => ({
  analytics: state.analytics,
  funnels: state.funnels,
  botId: state.activeBot?.id,
})

export default withRouter(connect(mapStateToProps)(Analytics))
