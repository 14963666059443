import React, {MouseEvent} from 'react'
import {ChatBotDeliverablesButtons} from './ChatBotDeliverablesButtons'
import {ChatBotDeliverablesButtonsExcel} from './ChatBotDeliverablesButtonsExcel'
import classes from './styles.module.scss'

interface ChatBotDeliverablesBlockProps {
  title: string
  viewUrl?: string
  downloadUrl?: string
  excelLink?: string
  openPDFHandler: (e: MouseEvent<HTMLAnchorElement>, viewUrl: string) => void
  username?: string
  hasDownloadExcelButton: boolean
}

export const ChatBotDeliverablesBlock: React.FC<ChatBotDeliverablesBlockProps> = ({
  title,
  viewUrl,
  downloadUrl,
  excelLink,
  openPDFHandler,
  username,
  hasDownloadExcelButton
}) => {

  return (
    <div className={classes.sectionWrapper}>
      <div className={classes.title}>
        {title}
      </div>
      <div className={classes.buttonContainer}>
        <ChatBotDeliverablesButtons
          viewUrl={viewUrl}
          downloadUrl={downloadUrl}
          openPDFHandler={openPDFHandler}
        />
        {hasDownloadExcelButton && <ChatBotDeliverablesButtonsExcel
          excelLink={excelLink}
          username={username}
        />}
      </div>
    </div>
  )
}
