import {store} from '../../../index'
import {ResultsDeliveryInfo} from '../api/model/ContactInfo'

export const SAVE_RESULTS_DELIVERY_INFO = 'SAVE_RESULTS_DELIVERY_INFO'
export const CLEAR_RESULTS_DELIVERY_INFO = 'CLEAR_RESULTS_DELIVERY_INFO'

export const saveResultsDeliveryInfo = (resultsDeliveryInfo: ResultsDeliveryInfo) => {
  store.dispatch({
    type: SAVE_RESULTS_DELIVERY_INFO,
    resultsDeliveryInfo,
  })
}

export const clearResultsDeliveryInfo = () => {
  store.dispatch({
    type: CLEAR_RESULTS_DELIVERY_INFO,
  })
}
