import React from 'react'
import PersonalInfoSection from '../personalInfoSection/PersonalInfoSection'
import InsuranceCardPhotos from '../insuranceCard/photos/InsuranceCardPhotos'
import GTKitButtonsBlock from '../gtKit/GTKitButtonsBlock'
import PostTestSection from '../postTestSection/PostTestSection'
import ConfirmResultsSection from '../confirmResultsSection/ConfirmResultsSection'
import classes from './styles.module.scss'

interface UserInfoRightContentProps {
    match: any
}

export const UserInfoRightContent: React.FC<UserInfoRightContentProps> = ({match}) => {

  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionTitle}>
          Genetic test kit request
      </div>
      <PersonalInfoSection/>
      <InsuranceCardPhotos/>
      <GTKitButtonsBlock match={match}/>
      <PostTestSection match={match}/>
      <ConfirmResultsSection match={match}/>
    </div>
  )
}
