/**
 * Created by Admin on 26.02.2018.
 */
import React from 'react'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { withRouter } from 'react-router-dom'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  withStyles,
} from '@material-ui/core'

const styles = theme => ({
  wrapHeader: {
    display: 'flex',
    width: '100%',
  },
  wrapNameAndPhrases: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  description: {
    fontFamily: 'Lato',
    fontSize: 12,
    color: theme.palette.text.gray,
    marginBottom: 0,
  },
  name: {
    fontFamily: 'Lato',
    fontSize: 18,
    marginBottom: 0,
    color: theme.palette.text.header,
  },
  flowExp: {
    borderRadius: '10px !important',
    overflow: 'hidden',
    margin: '16px 0 0 !important',
    boxShadow: ' 4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    '& >div': {
      padding: '0 40px',
    },
  },
  mainWrap: {
    borderRadius: 10,
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    '& [class*="MuiIconButton"]': {
      right: 24,
    },
    '& [class*="MuiExpansionPanel"]:not([class*="MuiIconButton"])': {
      margin: '24px 0',
    },
    "& [class*='MuiExpansionPanel']:before ": {
      display: 'none',
    },
    '::before': {
      display: 'none',
    },
  },
})

const TabTemplate = ({
  classes,
  tab,
  expanded,
  onChange,
  children,
  button,
  detailsStyle,
}) => (
  <ExpansionPanel
    className={classes.flowExp}
    expanded={expanded}
    onChange={onChange}>
    <ExpansionPanelSummary
      className={`${classes.mainWrap} summary`}
      expandIcon={<ExpandMoreIcon />}>
      <div className={classes.wrapHeader}>
        <div
          style={{
            marginRight: 24,
            display: 'flex',
            alignItems: 'center',
          }}>
          {tab.icon}
        </div>
        {tab.name && tab.phrasesNumber ? (
          <div className={classes.wrapNameAndPhrases}>
            <p className={classes.name}>{tab.name}</p>
            <p className={classes.description}>{tab.phrasesNumber} phrases</p>
          </div>
        ) : (
          <div>
            <p className={classes.name}>{tab.name}</p>
            <p className={classes.description}>{tab.description}</p>
          </div>
        )}
        {button && (
          <div style={{ marginLeft: 'auto', alignSelf: 'center' }} onClick={(e) => e.stopPropagation()}>
            {button}
          </div>
        )}
      </div>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails
      style={detailsStyle || { display: 'block', padding: '0 0 24px' }}>
      {children}
    </ExpansionPanelDetails>
  </ExpansionPanel>
)

TabTemplate.propTypes = {
  tab: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles, { withTheme: true })(TabTemplate))
