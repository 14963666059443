import React, { useContext, useEffect, useLayoutEffect } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'

import TextMessage from '../TextMessage'
import TextMessageButton from '../TextMessageButton'
import ImageMessage from '../ImageMessage'
import AudioMessage from '../AudioMessage'
import VideoMessage from '../VideoMessage'
import TemplateMessage from '../CarouselMessage'
import MessageWrap from '../MessageWrap'

import ActionMessage from '../ActionMessage'
import { styles } from './styles'
import { ChatHistoryElementType } from 'models/ChatMessagesTypes'
import { SharedWebsocketContext } from 'contexts/webSockets'
import { READ } from 'constants/statuses'
import ConversationIdLine from '../ConversationIdLine'

interface Props {
  msg: ChatHistoryElementType
  timezone: string
  reverse: boolean
  highlight: boolean
  showConversationId: boolean
  showReactionElementText: boolean
}

const Message: React.FC<Props> = props => {
  const { msg, timezone, reverse, highlight, showConversationId, showReactionElementText } = props
  const { sendStatus } = useContext(SharedWebsocketContext)

  const message = msg?.messagePreview ? JSON.parse(msg.messagePreview) : null
  const nativeMessage = msg?.nativeMessage ? JSON.parse(msg.nativeMessage) : null
  const lastEventAt = moment(msg.lastEventAt).add(timezone, 'hours').format('hh:mm A')

  const isTextMessage = ((message?.text && !message?.buttons?.length) || message?.postback) && !message.imageUrl
  const isTextMessageWithButtons = message?.text && message?.buttons?.length
  const isTemplateMessage = message?.elements?.length
  const mediaType = _.get(message, 'attachments[0].type') || _.get(message, 'media[0].type')
  const isImageMessage = message?.imageUrl || mediaType?.toLowerCase() === 'image'
  const isAudioMessage = mediaType?.toLowerCase() === 'audio'
  const isVideoMessage = mediaType?.toLowerCase() === 'video'
  const mediaUrl = _.get(message, 'media[0].url') || _.get(message, 'attachments[0].payload.url')

  const isRtl = message?.isRtl || msg?.isRtl
  const isReceived = reverse ? !message?.isFromUser : message?.isFromUser
  const enableReaction = _.get(nativeMessage, 'message.enableReaction', false)
  const reaction = _.get(message, 'reaction', {title: '', value: ''})

  useEffect(() => {
    onVisibilityChange()
  }, [])

  useLayoutEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange)

    return () => document.removeEventListener('visibilitychange', onVisibilityChange)
  }, [])

  const onVisibilityChange = () => {
    if (document.visibilityState === 'visible' && !msg?.isFromBot && msg?.messageStatus !== READ) {
      sendStatus(READ, msg)
    }
  }

  const renderMessage = () => {
    if (isTextMessage) {
      return <TextMessage message={message} isRtl={isRtl} isReceived={isReceived} highlight={highlight} />
    } else if (isTextMessageWithButtons) {
      return <TextMessageButton message={message} isRtl={isRtl} highlight={highlight} />
    } else if (isImageMessage) {
      return <ImageMessage imgUrl={message.imageUrl || mediaUrl} highlight={highlight} />
    } else if (isAudioMessage) {
      return <AudioMessage url={mediaUrl} />
    } else if (isVideoMessage) {
      return <VideoMessage url={mediaUrl} />
    } else if (isTemplateMessage) {
      return <TemplateMessage isRtl={isRtl} payload={message} highlight={highlight} />
    }
  }

  return (
    <>
      {nativeMessage?.action && <ActionMessage action={nativeMessage?.action} />}

      {showConversationId && <ConversationIdLine conversationId={msg?.conversationId} />}

      {message && (
        <MessageWrap
          lastEventAt={lastEventAt}
          isReceived={isReceived}
          status={msg?.messageStatus}
          enableReaction={enableReaction}
          reaction={reaction}
          showReactionElementText={showReactionElementText}
        >
          {renderMessage()}
        </MessageWrap>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  timezone: state.activeBot?.timezone,
})

export default withStyles(styles)(connect(mapStateToProps)(Message))
