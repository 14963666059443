import { COMET_GREY, EGYPTIAN_BLUE } from 'constants/colors'
import styled from 'styled-components'

const Title = styled.h5`
  font-size: 14px;
  padding-top: 16px;
  margin-bottom: 0;
  color: ${COMET_GREY};
`

const LinkContainer = styled.div`
  padding: 12px 0;
`

const Link = styled.a`
  text-decoration: none;
  font-size: 14px;
  color: ${EGYPTIAN_BLUE};
`

const Container = styled.div`
  max-height: 520px;
  overflow-y: auto;
`

const HideOverflow = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export { Container, Title, LinkContainer, Link, HideOverflow }
