import { NodeDataInterface } from '../CustomNode/CustomNode'

export const relativeInfoFontStyleHandler = (data: NodeDataInterface) => {
  const relativeInfoLength = data.relativeInfo?.length
  let fontSize: number
  let lineHeight: number

  switch (true) {
    case relativeInfoLength > 18:
      fontSize = 14
      lineHeight = 18
      break
    case relativeInfoLength > 16:
      fontSize = 16
      lineHeight = 20
      break
    case relativeInfoLength > 12:
      fontSize = 20
      lineHeight = 22
      break
    default:
      fontSize = 22
      lineHeight = 24
  }

  return {
    fontSize,
    lineHeight
  }
}

export const diagnosesFontStyleHandler = (data: NodeDataInterface) => {
  const diagnosesLength = data.diagnoses?.length
  let fontSize: number
  let lineHeight: number

  switch (true) {
    case diagnosesLength > 90:
      fontSize = 12
      lineHeight = 16
      break
    case diagnosesLength > 60:
      fontSize = 13
      lineHeight = 17
      break
    case diagnosesLength > 48:
      fontSize = 18
      lineHeight = 19
      break
    case diagnosesLength > 38:
      fontSize = 19
      lineHeight = 20
      break
    default:
      fontSize = 22
      lineHeight = 26
  }

  return {
    fontSize,
    lineHeight
  }
}
