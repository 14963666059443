import { store } from '../../../index'
import {LifeCenterUserTableRow} from '../api/model/LifeCenterUserTableView'

export const ADD_CONTACT = 'ADD_CONTACT'
export const SAVE_CONTACTS = 'SAVE_CONTACTS'
export const CLEAR_CONTACTS = 'CLEAR_CONTACTS'

export const addContact = (contact: LifeCenterUserTableRow) => {
  store.dispatch({
    type: ADD_CONTACT,
    contact,
  })
}

export const saveContacts = (contacts: LifeCenterUserTableRow[]) => {
  store.dispatch({
    type: SAVE_CONTACTS,
    contacts,
  })
}

export const clearContacts = () => {
  store.dispatch({
    type: CLEAR_CONTACTS,
  })
}
