import React from 'react'
import Typography from '@material-ui/core/Typography'

import { getFacebookPages } from '../../../../api/facebook'

import { useDisconnectPageModalContext } from './context'
import { useFacebookActivePagesContext } from '../FacebookActivePages/context'

import { Dialog } from 'uiKit/UI/Dialog'
import { DialogTitle } from 'uiKit/UI/DialogTitle'
import { DialogActions } from 'uiKit/UI/DialogActions'
import SubmitButton from 'uiKit/buttons/SubmitButton'
import LoaderSmall from 'uiKit/loaders/loaderSmall'

import { alertSuccess, alertError } from 'api'

interface Props {
  classes: any
  botId: number
  fbToken: string
}

export const DisconnectPageModal: React.FC<Props> = props => {
  const { classes } = props
  const { page, isOpenDisconnectPageModal, setIsOpenDisconnectPageModal } = useDisconnectPageModalContext()
  const { disconnectFacebookPage, isLoading, getPages } = useFacebookActivePagesContext()

  const handleClose = () => {
    if (!isLoading) {
      setIsOpenDisconnectPageModal(false)
    }
  }

  const submit = () =>
    disconnectFacebookPage(props.botId, page)
      .then(() => {
        alertSuccess('Page disconnected successfully')
        getFacebookPages(props.botId, props.fbToken).then(() => getPages(props.botId))
      })
      .catch(error => alertError(error.message || 'Unsubscribe page error'))
      .finally(() => setIsOpenDisconnectPageModal(false))

  return (
    <Dialog
      PaperProps={{}}
      open={isOpenDisconnectPageModal}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      classes={{
        root: classes.root,
      }}>
      {isLoading ? (
        <div className={classes.loaderScreen}>
          <LoaderSmall showLoader={true} />
        </div>
      ) : (
        <>
          {page && (
            <DialogTitle disableTypography id="alert-dialog-slide-title">
              <Typography component="h5">
                {`Are you sure you want to disconnect ${page.pageName} Facebook page?`}
              </Typography>
            </DialogTitle>
          )}
          <DialogActions>
            <SubmitButton title="Disconnect" disabled={isLoading} onClick={submit} type="warning" />
            <SubmitButton empty title="Cancel" onClick={handleClose} styles={{ borderRadius: 4, marginRight: 8 }} />
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
