/* eslint-disable */
import React from 'react'

export const EnterArrow = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.375 9.83984L5 14.0398L9.375 18.2398"
        stroke="#11C314"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 4.80078V10.6808C19 12.5365 17.433 14.0408 15.5 14.0408H5"
        stroke="#11C314"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
