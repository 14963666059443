import styled from 'styled-components'
import { COMET_GREY } from 'constants/colors'

const EmptyNlpScreen = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const Image = styled.img`
  width: 64px;
  height: 64px;
  opacity: 0.2;
  margin-bottom: 16px;
`

const Text = styled.p`
  margin: 0 0 24px;
  color: ${COMET_GREY};
  font-size: 14px;
`

export { EmptyNlpScreen, Image, Text }
