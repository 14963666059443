import styled from 'styled-components'
import { FIORD_BLUE } from 'constants/colors'

const TrainingContainer = styled.div`
  height: 100%;
`

const TitleWrap = styled.div`
  display: flex;
  align-items: self-start;
  gap: 14px;
`

const FiltersWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
`

const SearchWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`

const Title = styled.h1`
  font-family: Lato, serif;
  font-weight: bold;
  font-size: 24px;
  color: ${FIORD_BLUE};
  flex-basis: 15%;
`

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
  gap: 14px;
`

const SelectorWrap = styled.span`
  width: 190px;
`

export { TrainingContainer, TitleWrap, Title, Header, FiltersWrap, SearchWrap, SelectorWrap }
