import {BASE_URL} from '../../../configs'
import {alertError, alertSuccess, createJsonHeaders, logoutOnRequestOrResponseJson} from '../../../api'
import {addContactsSize, saveContactsSize} from '../actions/contactsSizeAction'
import {addContact, clearContacts, saveContacts} from '../actions/contactsAction'
import {updateContactsPage} from '../actions/contactsPageAction'
import {closeInviteUserModal, closeUserAccessModal} from '../actions/contactsModalsAction'
import {LifeCenterUserTableView} from './model/LifeCenterUserTableView'

export const getPagingContacts = (page: number, botId: number) => {
  return fetch(
    BASE_URL +
        `/bot/${botId}/contacts/table?page=${page}`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'GET',
    },
  ).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then((json: LifeCenterUserTableView) => {
        saveContactsSize(json.totalCount)
        saveContacts(json.lifeCenterUserTableRows)
        return json.lifeCenterUserTableRows
      })
    }
    response.text().then(text => alertSuccess(text))
  })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong at getting contacts please ping support!',
      )
    })
}

export const inviteNewUser = (body: any, botId: number, contactsSize: number) => {
  return fetch(
    BASE_URL +
        `/bot/${botId}/contacts/create-user`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'POST',
      body: JSON.stringify(body)
    },
  ).then(response => {
    if (response.status === 200) {
      return logoutOnRequestOrResponseJson(response).then(json => {
        closeInviteUserModal()
        updateContactsPage(0)
        addContact(json.lifeCenterUserTableRowDto)
        addContactsSize(contactsSize)
        alertSuccess(json.message)
        return json
      })
    }
  })
    .catch(function(error) {
      alertError(
        'Sorry but something going wrong at inviting new user, please ping support!',
      )
    })
}

export const updateUserAccess = (botId: number, contactId: number, active: boolean, contactsPage: number) => {
  return fetch(`${BASE_URL}/bot/${botId}/contacts/update-status`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'POST',
      body: JSON.stringify({
        contactId,
        active
      })
    }
  ).then(response => {
    if (response.status === 200) {
      closeUserAccessModal()
      clearContacts()
      getPagingContacts(contactsPage, botId)
    }
  }).catch(() => {
    alertError('Sorry but something going wrong at inviting new user, please ping support!')
  })
}
