import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import classes from './styles.module.scss'

interface ContactsTableTotalCountProps {
  contactsSize: number
}

const ContactsTableTotalCount: React.FC<ContactsTableTotalCountProps> = ({contactsSize}) => {
  return (
    <div className={classes.sizeWrapper}>
      {contactsSize} {contactsSize === 1 ? 'contact' : 'contacts'}
    </div>
  )
}

const mapStateToProps = state => ({
  contactsSize: state.contactsSize
})

export default withRouter(connect(mapStateToProps)(ContactsTableTotalCount))
