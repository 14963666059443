import React from 'react'

interface DownloadIconProps {
    disabled?: boolean
}

export const DownloadIcon: React.FC<DownloadIconProps> = ({disabled}) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        /* eslint-disable-next-line max-len */
        d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10"
        stroke={!disabled ? '#1658F3' : '#ACB0C0'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M4.66663 6.66666L7.99996 9.99999L11.3333 6.66666"
        stroke={!disabled ? '#1658F3' : '#ACB0C0'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M8 10V2"
        stroke={!disabled ? '#1658F3' : '#ACB0C0'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"/>
    </svg>
  )
}
