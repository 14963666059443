import React, { useEffect, useMemo, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import 'react-chartjs-2'
import { connect } from 'react-redux'

import AreaChart from './components/AreaChart'
import CircleChart from './components/CircleChart'
import HourlyChart from './components/HourlyChart'
import AreaChartButton from './components/AreaChartButton'
import CircleChartButton from './components/CircleChartButton'
import HourlyChartButton from './components/HourlyChartButton'
import ChartContainer from '../../uiKit/ChartContainer'
import NamedCounter from './components/NamedCounter'
import ChannelsCircleChart from './components/ChannelsCircleChart'
import ChannelsChartButton from './components/ChannelsChartButton'
import Headline from 'uiKit/texts/Headline'
import DateControl from 'uiKit/DateControlNew'
import { DateControlProvider } from 'contexts/DateControlContext'

import { isObjectEmpty } from '../../helpers/isObjectEmpty'

import * as S from './Dashboard.style'

import {
  areaChartTooltipText,
  channelsChartTooltipText,
  circleChartContainerTitle,
  conversationsCoveredTooltipText,
  hourlyChartContainerTitle,
  hourlyChartTooltipText,
} from './constants/chartsTexts'

import classes from './styles.module.scss'
import { usersChartDataType } from 'models/DashboardTypes'
import { setBrowserTabTitle } from '../../helpers/setBrowserTabTitle'

interface Props {
  usersStatistics: usersChartDataType
  supportStatistics: any
  popularHoursStatistics: any
  match: any
  activeBot: any
}

const Dashboard: React.FC<Props> = ({
  usersStatistics,
  supportStatistics,
  popularHoursStatistics,
  match,
  activeBot,
}) => {
  const { botId } = match.params
  const containerRef = useRef(null)

  useEffect(() => {
    containerRef.current.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setBrowserTabTitle(`${activeBot?.name} - Dashboard`)
  }, [activeBot])

  const platformsNumber = useMemo(() => {
    if (usersStatistics?.points && !isObjectEmpty(usersStatistics?.points)) {
      return Object.values(usersStatistics?.points)[0]?.map(({ platform }) => platform)?.length > 2
    }
  }, [usersStatistics?.points])

  const activeUsersNumber = useMemo(() => {
    if (usersStatistics?.usersBetweenDates && !isObjectEmpty(usersStatistics?.usersBetweenDates)) {
      return Object.values(usersStatistics?.usersBetweenDates).reduce((acc, count) => acc + count, 0)
    }
    return 0
  }, [usersStatistics?.usersBetweenDates])

  return (
    <div className={classes.container} ref={containerRef}>
      <DateControlProvider>
        <S.HeadlineWrap>
          <Headline title="Dashboard" />
          <DateControl />
        </S.HeadlineWrap>
        <>
          <div className={classes.row}>
            <ChartContainer
              title={'Active users'}
              tooltipText={areaChartTooltipText}
              totalUsers={!platformsNumber && usersStatistics?.totalUsers}
              activeUsers={!platformsNumber && activeUsersNumber}
              button={usersStatistics && <AreaChartButton />}>
              <AreaChart isMultipleChannels={!!platformsNumber} botId={botId}/>
            </ChartContainer>

            {platformsNumber && (
              <div className={classes.flexBlockColumn}>
                <div className={classes.flexBlock}>
                  <NamedCounter value={activeUsersNumber} title={'Active users'} />
                  <NamedCounter value={usersStatistics?.totalUsers} title={'Total users'} />
                </div>

                <ChartContainer
                  title={'Users per channel'}
                  tooltipText={channelsChartTooltipText}
                  button={usersStatistics && <ChannelsChartButton />}>
                  <ChannelsCircleChart />
                </ChartContainer>
              </div>
            )}
          </div>

          <div className={classes.row}>
            <ChartContainer
              title={circleChartContainerTitle}
              tooltipText={conversationsCoveredTooltipText}
              button={supportStatistics && <CircleChartButton />}>
              <CircleChart botId={botId}/>
            </ChartContainer>

            <ChartContainer
              title={hourlyChartContainerTitle}
              tooltipText={hourlyChartTooltipText}
              button={popularHoursStatistics && <HourlyChartButton />}>
              <HourlyChart botId={botId}/>
            </ChartContainer>
          </div>
        </>
      </DateControlProvider>
    </div>
  )
}

const mapStateToProps = (state: {
  usersStatistics: usersChartDataType
  supportStatistics
  popularHoursStatistics
  activeBot
}) => ({
  usersStatistics: state.usersStatistics,
  supportStatistics: state.supportStatistics,
  popularHoursStatistics: state.popularHoursStatistics,
  activeBot: state.activeBot,
})

export default withRouter(connect(mapStateToProps)(Dashboard))
