import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Select from 'react-select'

import { TABLE_PAGINATION_ROWS_PER_PAGE_OPTIONS } from '../../constants/styles'

import { usePaginationTableContext } from '../PaginatedTable/context'
import { ChevronLeftIcon, ChevronRightIcon } from '../icons/Icons'

export const TableFooterPagination = props => {
  const { classes } = props
  const {
    page,
    allPagesCount,
    isLastPage,
    isFirstPage,
    handlePrevPage,
    handleNextPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePaginationTableContext()

  return (
    <TableRow>
      <TableCell colSpan={props.colSpan}>
        <div className={classes.container}>
          <div className={classes.showItemsBlock}>
            <span>Show</span>
            <Select
              className={classes.select}
              styles={{
                control: styles => ({ ...styles, borderRadius: 10 }),
                indicatorSeparator: () => ({ display: 'none' }),
                singleValue: styles => ({ ...styles, color: '#1658F3', margin: '0 16px'}),
                menu: styles => ({ ...styles, top: '-110px', overflow: 'scroll', maxHeight: 160}),
              }}
              defaultValue={TABLE_PAGINATION_ROWS_PER_PAGE_OPTIONS[0]}
              options={TABLE_PAGINATION_ROWS_PER_PAGE_OPTIONS}
              onChange={handleChangeRowsPerPage}
            />
          </div>
          <div className={classes.pages}>
            <button onClick={handlePrevPage} disabled={isFirstPage}>
              <ChevronLeftIcon />
            </button>
            <p>
              Page
              <input
                type="number"
                value={page}
                min={1}
                max={allPagesCount}
                onClick={e => e.target.select()}
                onChange={handleChangePage}
              />{' '}
              from {allPagesCount}
            </p>
            <button onClick={handleNextPage} disabled={isLastPage}>
              <ChevronRightIcon />
            </button>
          </div>
        </div>
      </TableCell>
    </TableRow>
  )
}
