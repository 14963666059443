import { store } from '../../../index'

export const UPDATE_CONTACTS_PAGE = 'UPDATE_CONTACTS_PAGE'

export const updateContactsPage = contactsPage => {
  store.dispatch({
    type: UPDATE_CONTACTS_PAGE,
    contactsPage,
  })
}
