import React, { ReactElement } from 'react'
import { withStyles } from '@material-ui/core'

import { styles } from './styles'
import { DELIVERED, READ } from 'constants/statuses'
import { DeliveredStatusIcon, ReadStatusIcon, SentStatusIcon } from 'uiKit/icons/StatusIcons.ts'
import ReactionIconTooltip from 'uiKit/ReactionBar/ReactionIconTooltip'
import { ReactionType } from 'models/MessageTypes'

interface Props {
  classes: any
  isReceived: boolean
  lastEventAt: string
  status: string
  children: ReactElement
  enableReaction: boolean
  reaction: ReactionType
  showReactionElementText: boolean
}

const MessageWrap: React.FC<Props> = props => {
  const {
    classes,
    isReceived,
    lastEventAt,
    children,
    status,
    enableReaction,
    reaction,
    showReactionElementText
  } = props

  const renderStatus = (status: string) => {
    if (status === DELIVERED) {
      return <DeliveredStatusIcon />
    } else if (status === READ) {
      return <ReadStatusIcon />
    }

    return <SentStatusIcon />
  }

  return (
    <div className={isReceived ? classes.containerReceived : classes.containerSend}>
      {enableReaction &&
        <ReactionIconTooltip
          reaction={reaction}
          showReactionElementText={showReactionElementText}
        />
      }
      {children && (
        <div className={classes.statusWrap}>
          {lastEventAt && <span className={classes.timeZone}>{lastEventAt}</span>}
          {isReceived === false && renderStatus(status)}
        </div>
      )}
      {children}
    </div>
  )
}

export default withStyles(styles)(MessageWrap)
