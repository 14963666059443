import React, { CSSProperties } from 'react'
import Select, { StylesConfig } from 'react-select'
import { OptionTypeBase } from 'react-select/src/types'
import classes from './styles.module.scss'

const fontStyles = (fz = 14, fw = 400, lh = '19px') => ({
  fontStyle: 'normal',
  fontWeight: fw,
  fontSize: fz,
  lineHeight: lh
})

const colourStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    borderRadius: 10,
    borderColor: '#C7CAD6',
    boxShadow: 'transparent',
    '&:hover': {}
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#354052',
    margin: 0,
    ...fontStyles()
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#A3A3A3',
    margin: 0,
    ...fontStyles()
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '10px 0 10px 24px',
    flexWrap: 'nowrap'
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    backgroundColor: 'transparent'
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    '&:hover': {
      cursor: 'pointer'
    }
  }),
  menu: (styles) => ({
    ...styles,
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.14)',
    borderRadius: 4
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
    borderRadius: 4
  }),
  option: (styles, { isDisabled, isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? '#5A98F7' : undefined,
    color: isFocused ? '#FFF' : '#354052',
    cursor: isDisabled ? 'not-allowed' : 'default',
    padding: 16,
    ...fontStyles(14, undefined, '20px'),
    '&:hover': {
      backgroundColor: '#5A98F7',
      color: '#FFF'
    },
    '&:active': {
      ...styles[':active'],
      backgroundColor: '#5A98F7',
      color: '#FFF'
    }
  })
}

interface Props {
  title?: string
  options: OptionTypeBase[]
  placeholder: string
  inputTitleStyles: CSSProperties
  onChange: (value: any) => void
  error?: boolean
  errorMessage?: string
}

const SelectDropdown: React.FC<Props> = ({
  title,
  options,
  placeholder,
  inputTitleStyles,
  onChange,
  error,
  errorMessage
}) => {

  return (
    <div>
      {title && (
        <p className={classes.selectTitle} style={inputTitleStyles}>
          {title}
        </p>
      )}
      <Select
        placeholder={placeholder}
        options={options}
        styles={colourStyles}
        isClearable={false}
        isSearchable={false}
        menuPlacement='auto'
        minMenuHeight={250}
        onChange={onChange}
      />
      {error && errorMessage && (
        <p className={classes.errorMessage}>
          {errorMessage}
        </p>
      )}
    </div>
  )
}

export default SelectDropdown
