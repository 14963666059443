import React, {useState} from 'react'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import {FormControlLabel} from '@material-ui/core'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import {ArrowDownIcon} from '../common/icons/ArrowDownIcon'
import moment from 'moment-timezone'
import {selectedFile} from '../../../flowBuilder/api/fileService'

import DateRangePicker from 'react-bootstrap-daterangepicker'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-daterangepicker/daterangepicker.css'
import {alertError} from '../../../../api'
import {Timeit} from 'react-timeit'
import {scheduleResultDelivery} from '../../api/contactInfoApi'
import {ResultStatus, ScheduleResultFileType, ScheduleResultTexts} from '../../api/model/ContactInfo'
import classes from './styles.module.scss'
import FileDropZone from './components/FileDropZone'

const cyrillicPattern = /[\u0400-\u04FF]/

interface ScheduleResultsDeliveryModalProps {
  match: any
  onClose: () => void
}

export const ScheduleResultsDeliveryModal: React.FC<ScheduleResultsDeliveryModalProps> = ({match, onClose}) => {
  const [didUserGetGeneticTesting, setDidUserGetGeneticTesting] = useState<boolean>(true)
  const [resultsStatus, setResultsStatus] = useState<ResultStatus>(ResultStatus.NEGATIVE)
  const [dateOfGeneticTesting, setDateOfGeneticTesting] = useState<moment.Moment>(moment.tz('America/New_York'))
  const [dateOfGeneticTestingSelected, setDateOfGeneticTestingSelected] = useState<boolean>(false)
  const [scheduleResultsDelivery, setScheduleResultsDelivery] = useState<moment.Moment>(moment.tz('America/New_York'))
  const [scheduleResultsDeliverySelected, setScheduleResultsDeliverySelected] = useState<boolean>(false)
  const [time, setTime] = useState<string>(null)
  const [timeSelected, setTimeSelected] = useState<boolean>(false)
  const [userFile, setUserFile] = useState({
    labResults: null,
    familyCommunicationLetter: null,
    gtResultsLetter: null
  })
  const [familyCommunicationLetterSelected, setFamilyCommunicationLetterSelected] = useState(false)
  const [gtResultsLetterSelected, setGtResultsLetterSelected] = useState(false)
  const [filesUrl, setFilesUrl] = useState({
    labResults: null,
    familyCommunicationLetter: null,
    gtResultsLetter: null
  })
  const [uploadProgress, setUploadProgress] = useState({
    labResults: 0,
    familyCommunicationLetter: 0,
    gtResultsLetter: 0
  })
  const [isDateOfGeneticTestingValid, setIsDateOfGeneticTestingValid] = useState<boolean>(true)
  const [isScheduleResultsDeliveryValid, setIsScheduleResultsDeliveryValid] = useState<boolean>(true)
  const [isTimeValid, setIsTimeValid] = useState<boolean>(true)
  const [fileValidation, setFileValidation] = useState({
    labResults: true,
    familyCommunicationLetter: true,
    gtResultsLetter: true
  })
  const [isSubmitButtonWasClicked, setIsSubmitButtonWasClicked] = useState<boolean>(false)
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(false)
  const [dateOfGeneticTestingPickerOpen, setDateOfGeneticTestingPickerOpen] = useState<boolean>(false)
  const [scheduleResultDeliveryPickerOpen, setScheduleResultDeliveryPickerOpen] = useState<boolean>(false)
  const [timePickerOpen, setTimePickerOpen] = useState<boolean>(false)

  const validateFields = (
    didUserGetGeneticTesting: boolean,
    dateOfGeneticTestingSelected: boolean,
    scheduleResultsDeliverySelected: boolean,
    timeSelected: boolean,
    familyCommunicationLetterSelected: boolean,
    gtResultsLetterSelected: boolean,
    isSubmitButtonWasClicked: boolean
  ) => {

    if (isSubmitButtonWasClicked) {
      setIsDateOfGeneticTestingValid(dateOfGeneticTestingSelected)
      setIsScheduleResultsDeliveryValid(scheduleResultsDeliverySelected)
      setIsTimeValid(timeSelected)
      setFileValidation({
        ...fileValidation,
        familyCommunicationLetter: familyCommunicationLetterSelected,
        gtResultsLetter: gtResultsLetterSelected
      })
    }

    setDisableSubmitButton(
      (
        !dateOfGeneticTestingSelected ||
        !scheduleResultsDeliverySelected ||
        !timeSelected ||
        !familyCommunicationLetterSelected ||
        !gtResultsLetterSelected
      ) && isSubmitButtonWasClicked
    )

    return (
      didUserGetGeneticTesting &&
      dateOfGeneticTestingSelected &&
      scheduleResultsDeliverySelected &&
      timeSelected &&
      familyCommunicationLetterSelected &&
      gtResultsLetterSelected
    )
  }

  const handleSubmitButtonClick = e => {
    const isSubmitButtonWasClicked = true
    setIsSubmitButtonWasClicked(isSubmitButtonWasClicked)

    const isAllFieldsValid = validateFields(
      didUserGetGeneticTesting,
      dateOfGeneticTestingSelected,
      scheduleResultsDeliverySelected,
      timeSelected,
      familyCommunicationLetterSelected,
      gtResultsLetterSelected,
      isSubmitButtonWasClicked
    )

    if (isAllFieldsValid) {
      scheduleResultDelivery({
        dateOfGeneticTestingDate: dateOfGeneticTesting,
        resultDeliveryDate: scheduleResultsDelivery,
        resultDeliveryTime: time,
        labResultsURL: filesUrl.labResults,
        geneticTestLetterURL: filesUrl.gtResultsLetter,
        familyCommunicationLetterURL: filesUrl.familyCommunicationLetter,
        resultStatus: resultsStatus,
      }, match.params.botId, match.params.contactId)
      onClose()
    }
  }

  const handleNegativeResultBtnClick = () => {
    setResultsStatus(ResultStatus.NEGATIVE)
  }

  const handleVUSResultBtnClick = () => {
    setResultsStatus(ResultStatus.VUS)
  }

  const handlePositiveResultBtnClick = () => {
    setResultsStatus(ResultStatus.POSITIVE)
  }

  const setDateOfGeneticTestingDate = (event, picker) => {
    setDateOfGeneticTesting(moment.tz(picker.startDate, 'America/New_York'))
    setDateOfGeneticTestingSelected(true)
    setIsDateOfGeneticTestingValid(true)

    validateFields(
      didUserGetGeneticTesting,
      true,
      scheduleResultsDeliverySelected,
      timeSelected,
      familyCommunicationLetterSelected,
      gtResultsLetterSelected,
      isSubmitButtonWasClicked
    )
  }

  const setScheduleResultsDeliveryDate = (event, picker) => {
    setScheduleResultsDelivery(moment.tz(picker.startDate, 'America/New_York'))
    setScheduleResultsDeliverySelected(true)
    setIsScheduleResultsDeliveryValid(true)

    validateFields(
      didUserGetGeneticTesting,
      dateOfGeneticTestingSelected,
      true,
      timeSelected,
      familyCommunicationLetterSelected,
      gtResultsLetterSelected,
      isSubmitButtonWasClicked
    )
  }

  const setTimeDate = (time) => {
    setTime(time)
    setTimeSelected(true)
    setIsTimeValid(true)
    setTimePickerOpen(false)

    validateFields(
      didUserGetGeneticTesting,
      dateOfGeneticTestingSelected,
      scheduleResultsDeliverySelected,
      true,
      familyCommunicationLetterSelected,
      gtResultsLetterSelected,
      isSubmitButtonWasClicked
    )
  }

  const parseDate = (timestamp) => {
    const date = new Date(timestamp)
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
  }

  const handleUpload = (fileType: string) => {
    // Simulate file upload progress
    const isFamilyCommunicationLetterType = fileType === ScheduleResultFileType.FAMILY_COMMUNICATION_LETTER
    const isGTResultsLetterType = fileType === ScheduleResultFileType.GT_RESULTS_LETTER

    const interval = setInterval(() => {
      setUploadProgress(prev => {
        const progress = prev[fileType] + prev[fileType] * 0.5 + 1
        if (progress >= 100) {
          clearInterval(interval)

          isFamilyCommunicationLetterType && setFamilyCommunicationLetterSelected(isFamilyCommunicationLetterType)
          isGTResultsLetterType && setGtResultsLetterSelected(isGTResultsLetterType)

          const isFamilyCommunicationLetterSelected = isFamilyCommunicationLetterType ||
            familyCommunicationLetterSelected
          const isGTResultsLetterSelected = isGTResultsLetterType || gtResultsLetterSelected

          validateFields(
            didUserGetGeneticTesting,
            dateOfGeneticTestingSelected,
            scheduleResultsDeliverySelected,
            timeSelected,
            isFamilyCommunicationLetterSelected,
            isGTResultsLetterSelected,
            isSubmitButtonWasClicked
          )

          return {
            ...prev,
            [fileType]: 100
          }
        }

        return {
          ...prev,
          [fileType]: progress
        }
      })
    }, 300)
  }

  const defaultTime = () => {
    const date = new Date()
    date.setMinutes(date.getMinutes() + 10)
    let hours: string | number = date.getHours()
    let minutes: string | number = date.getMinutes()
    hours = hours < 10 ? '0' + hours : hours
    minutes = minutes < 10 ? '0' + minutes : minutes
    return hours + ':' + minutes
  }

  const handleFileChange = (event, fileType: string) => {
    const file = event.target.files[0]
    const preparedFileName = file.name.replace(/[^\w\s.\/]/g, '_').replace(/\s/g, '')
    const newFile = new File([file], preparedFileName, {type: file.type})
    if (file && file.size < 10 * 1024 * 1024) {
      if (cyrillicPattern.test(file.name)) {
        return alertError('File name can only contain Latin letters and numbers.')
      }
      handleUpload(fileType)
      setUserFile(prevState => ({
        ...prevState,
        [fileType]: file
      }))
      setFileValidation(prevState => ({
        ...prevState,
        [fileType]: true
      }))

      const fileToUpload = new FormData()
      fileToUpload.append('file', newFile)
      selectedFile(fileToUpload).then(res => {
        setFilesUrl(prevState => ({
          ...prevState,
          [fileType]: res.url
        }))
      })
    } else {
      alertError('File size exceeds the 10MB limit.')
    }
  }

  const handleDeleteFile = (fileType: string) => {
    const isFamilyCommunicationLetterType = fileType === ScheduleResultFileType.FAMILY_COMMUNICATION_LETTER
    const isGTResultsLetterType = fileType === ScheduleResultFileType.GT_RESULTS_LETTER
    setUserFile(prevState => ({
      ...prevState,
      [fileType]: null
    }))
    setFilesUrl(prevState => ({
      ...prevState,
      [fileType]: null
    }))
    setUploadProgress(prevState => ({
      ...prevState,
      [fileType]: 0
    }))
    isGTResultsLetterType && setGtResultsLetterSelected(false)
    isFamilyCommunicationLetterType && setFamilyCommunicationLetterSelected(false)
    if (isFamilyCommunicationLetterType || isGTResultsLetterType) {
      validateFields(
        didUserGetGeneticTesting,
        dateOfGeneticTestingSelected,
        scheduleResultsDeliverySelected,
        timeSelected,
        false,
        false,
        isSubmitButtonWasClicked
      )
    }
  }

  const handleRadioGroupChange = (e) => {
    const values = e.target.value === 'true'
    setDidUserGetGeneticTesting(values)
    if (!values) {
      onClose()
    }
  }

  return (
    <div className={classes.modalWrapper}>
      <h2 className={classes.header}>
                    Schedule results delivery
      </h2>
      <div>
        <span className={classes.geneticTestingTitle}>Did the user get genetic testing?</span>
        <RadioGroup
          className={classes.radioGroup}
          value={didUserGetGeneticTesting ? 'true' : 'false'}
          onChange={e => handleRadioGroupChange(e)}>
          <FormControlLabel
            value={'true'}
            control={<Radio
              color={'primary'}
            />}
            label="Yes"
          />
          <FormControlLabel
            value={'false'}
            control={<Radio
              color={'primary'}
            />}
            label="No"
          />
        </RadioGroup>
      </div>
      <div className={classes.resultBlock}>
        <span className={classes.resultsTitle}>What are the results</span>
        <div className={classes.resultBtnsWrapper}>
          <div
            className={resultsStatus === ResultStatus.NEGATIVE ?
              classes.negativeResultBtn : classes.notSelectedResultBtn}
            onClick={handleNegativeResultBtnClick}>
                            Negative
          </div>
          <div
            className={resultsStatus === ResultStatus.VUS ?
              classes.vusResultBtn : classes.notSelectedResultBtn}
            onClick={handleVUSResultBtnClick}>
                            VUS
          </div>
          <div
            className={resultsStatus === ResultStatus.POSITIVE ?
              classes.positiveResultBtn : classes.notSelectedResultBtn}
            onClick={handlePositiveResultBtnClick}>
                            Positive
          </div>
        </div>
      </div>
      <div className={classes.dateSelectionWrapper}>
        <span className={classes.dateTitle}>Date of genetic testing</span>
        <DateRangePicker
          singleDatePicker
          maxDate={moment.tz('America/New_York')}
          className={classes.dateRangePickerContainer}
          startDate={dateOfGeneticTesting}
          endDate={dateOfGeneticTesting}
          onApply={setDateOfGeneticTestingDate}>
          <div className={classes.chooseDate}
            onClick={e =>
              setDateOfGeneticTestingPickerOpen(!dateOfGeneticTestingPickerOpen)}>
            <span className={isDateOfGeneticTestingValid ?
              classes.chooseDatePlaceholder : classes.chooseDatePlaceholderError}>
              {dateOfGeneticTestingSelected ? parseDate(dateOfGeneticTesting) : 'Choose date'}
            </span>
            <div className={classes.iconWrapper}>
              <ArrowDownIcon/>
            </div>
          </div>
        </DateRangePicker>
      </div>
      <div className={classes.dateSelectionWrapper}>
        <span className={classes.dateTitle}>Schedule results delivery</span>
        <DateRangePicker
          singleDatePicker
          minDate={moment.tz('America/New_York')}
          className={classes.dateRangePickerContainer}
          startDate={scheduleResultsDelivery}
          endDate={scheduleResultsDelivery}
          onApply={setScheduleResultsDeliveryDate}>
          <div className={classes.chooseDate}
            onClick={e =>
              setScheduleResultDeliveryPickerOpen(!scheduleResultDeliveryPickerOpen)}>
            <span className={isScheduleResultsDeliveryValid ?
              classes.chooseDatePlaceholder : classes.chooseDatePlaceholderError}>
              {scheduleResultsDeliverySelected ? parseDate(scheduleResultsDelivery) : 'Choose date'}
            </span>
            <div className={classes.iconWrapper}>
              <ArrowDownIcon/>
            </div>
          </div>
        </DateRangePicker>
      </div>
      <div className={classes.dateSelectionWrapper}>
        <span className={classes.dateTitle}>Time</span>
        <div className={classes.chooseTimeWrapper}
          onClick={e => setTimePickerOpen(!timePickerOpen)}>
          <span className={isTimeValid ?
            classes.chooseDatePlaceholder : classes.chooseDatePlaceholderError}>
            {timeSelected ? time : 'Choose time'}</span>
          <div className={classes.iconWrapper}>
            <ArrowDownIcon/>
          </div>
        </div>
        {timePickerOpen && (
          <div className={classes.timeSelectorWrapper}>
            {/* eslint-disable-next-line max-len */}
            <span className={classes.dateTitle}>Please note that the scheduled delivery time should be at least 3 minutes ahead of your real-time</span>
            <Timeit defualtValue={time ? time : defaultTime()} onChange={e => setTime(e)}/>
            <div onClick={e => setTimeDate(time)}
              style={{color: '#1658F3', paddingTop: '24px', paddingBottom: '12px', cursor: 'pointer'}}>Done</div>
          </div>
        )}
      </div>
      <FileDropZone
        title={ScheduleResultTexts.labResultsTitle}
        subTitle={ScheduleResultTexts.labResultsSubTile}
        isFileValid={fileValidation.labResults}
        onChange={(e) => handleFileChange(e, ScheduleResultFileType.LAB_RESULTS)}
        handleDeleteFile={() => handleDeleteFile(ScheduleResultFileType.LAB_RESULTS)}
        uploadProgress={uploadProgress.labResults}
        selectedUserFile={userFile.labResults}
      />
      <FileDropZone
        title={ScheduleResultTexts.gtResultsLetterTitle}
        subTitle={ScheduleResultTexts.gtResultsLetterSubTitle}
        isFileValid={fileValidation.gtResultsLetter}
        onChange={(e) => handleFileChange(e, ScheduleResultFileType.GT_RESULTS_LETTER)}
        handleDeleteFile={() => handleDeleteFile(ScheduleResultFileType.GT_RESULTS_LETTER)}
        uploadProgress={uploadProgress.gtResultsLetter}
        selectedUserFile={userFile.gtResultsLetter}
      />
      <FileDropZone
        title={ScheduleResultTexts.familyCommunicationLetterTitle}
        subTitle={ScheduleResultTexts.familyCommunicationLetterSubTitle}
        isFileValid={fileValidation.familyCommunicationLetter}
        onChange={(e) => handleFileChange(e, ScheduleResultFileType.FAMILY_COMMUNICATION_LETTER)}
        handleDeleteFile={() => handleDeleteFile(ScheduleResultFileType.FAMILY_COMMUNICATION_LETTER)}
        uploadProgress={uploadProgress.familyCommunicationLetter}
        selectedUserFile={userFile.familyCommunicationLetter}
      />
      <SubmitButton
        disabled={disableSubmitButton}
        styles={{
          width: '97px',
          height: '48px',
          margin: '30px auto 0',
          display: 'block'
        }}
        onClick={handleSubmitButtonClick}
        title={'Schedule'}
      />
    </div>
  )
}
