import React from 'react'
import {NoContactsDataIcon} from './NoContactsDataIcon'
import classes from './styles.module.scss'

export const NoContactsData: React.FC = ({}) => {
  return (
    <div className={classes.wrap}>
      <NoContactsDataIcon/>
      <p className={classes.text}>You have no Contacts yet. Start by pressing the button -  Invite new user</p>
    </div>
  )
}
