import React from 'react'

interface ViewIconProps {
    disabled?: boolean
}

export const ViewIcon: React.FC<ViewIconProps> = ({disabled}) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4205_15176)">
        <path
          /* eslint-disable-next-line max-len */
          d="M0.666626 7.99999C0.666626 7.99999 3.33329 2.66666 7.99996 2.66666C12.6666 2.66666 15.3333 7.99999 15.3333 7.99999C15.3333 7.99999 12.6666 13.3333 7.99996 13.3333C3.33329 13.3333 0.666626 7.99999 0.666626 7.99999Z"
          stroke={!disabled ? '#1658F3' : '#ACB0C0'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"/>
        <path
          /* eslint-disable-next-line max-len */
          d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
          stroke={!disabled ? '#1658F3' : '#ACB0C0'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_4205_15176">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}
